<template>
	<div class="mpApply">
		<div class="titlebox" v-if="!['8'].includes(query.subType)">
			<el-button size="medium" type="text" @click="toback">返回</el-button>
			<div class="title">{{ title }}</div>
		</div>
		<entry isApproval v-if="query.subType === '8'" @cancel="entryCancel" />
		<manpowerPlanning isApproval v-if="query.subType === '12'" />
		<organizationalFunctions isApproval v-if="query.subType === '13'" />
		<organizationalCharge isApproval v-if="query.subType === '14'" />
		<baseInfo isApproval v-if="query.subType === '20'" />
		<el-row style="text-align: center; padding: 20px; background-color: #fff" v-if="!['8'].includes(query.subType)">
			<el-button @click="toback">取消</el-button>
			<el-button type="primary" @click="submit">下一步</el-button>
		</el-row>
	</div>
</template>
<script>
import manpowerPlanning from '../../../organizingpersonnel/organizing/organizational/manpowerPlanning.vue';
import organizationalFunctions from '../../../organizingpersonnel/organizing/organizational/organizationalFunctions.vue';
import organizationalCharge from '../../../organizingpersonnel/organizing/organizational/organizationalCharge';
import baseInfo from '../../../organizingpersonnel/humanaffairs/info.vue';
import entry from '../entry/index.vue'; // 入职录入
import { Loading } from 'element-ui';
export default {
	components: { manpowerPlanning, organizationalFunctions, organizationalCharge, entry, baseInfo },
	props: {},
	data() {
		return {};
	},
	computed: {
		query() {
			return this.$route.query;
		},
		title() {
			let str = '';

			switch (this.query.subType) {
				case '8':
					str = '入职申请';
					break;
				case '12':
					str = '人力计划调整申请';
					break;
				case '13':
					str = '组织职能调整申请';
					break;
				case '14':
					str = '组织负责人调整申请';
					break;
				case '20':
					str = '基本信息修改';
					break;

				default:
					break;
			}
			return str;
		}
	},
	created() {},
	mounted() {},
	watch: {},
	methods: {
		entryCancel(id) {
			this.cancelApply(id);
		},
		toback() {
			const loading = Loading.service({ target: '.mpApply' });

			let id = '';

			if (this.query.subType === '20' && this.query.id) {
				// 基本信息
				id = this.query.id;
			}
			this.cancelApply(id).then((res) => {
				loading.close();
				if (res) {
					this.$router.go(-1);
				}
			});
		},
		// 点击取消或上一步
		cancelApply(id) {
			const params = { approvalInfoId: this.query.approvalId };

			if (id) {
				params.businessId = id;
			}
			return this.$axios
				.post('/v1/approval/user/cancelApply', { request: params })
				.then((res) => {
					if (res.code) {
						return false;
					}
					return true;
				})
				.catch(() => {});
		},
		submit() {
			console.log('submit');
			this.$router.push({
				path: '/staff/orgSubmit',
				query: {
					approvalId: this.query.approvalId,
					subType: this.query.subType
				}
			});
		}
	},
	beforeDestroy() {
		console.log('beforeDestroy');
	}
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.mpApply {
	// background: #fff;
	padding: 30px;
}
</style>
