<template>
	<div class="app-container applay" v-loading="loading">
		<div class="titlebox" v-if="!isEntry">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">申请提交</div>
		</div>
		<el-form ref="form" :rules="rules" :model="form" label-width="150px">
			<!-- <div class="sections-container">
				<el-form-item prop="performanceName" label="申请类型">
					<el-select
						style="width: 400px"
						v-model="approvalType"
						placeholder="选择申请类型"
						value-key="approvalId"
						@change="changeApprovalProperties"
						:disabled="Number($route.query.applyId) ? true : false"
					>
						<el-option v-for="(item, i) in approvalTypes" :value="item" :label="item.approvalName" :key="i"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					v-for="(item, i) in properties"
					class="approval-form-item"
					:label="item.approvalPropertyName"
					:required="!!item.approvalPropertyRequired"
					:key="i"
				>
					<component
						:placeholder="item.approvalPropertyTip"
						v-model="item.value"
						v-if="'el-checkbox-group' === ccomponents[item.approvalPropertyType].name"
						:is="ccomponents[item.approvalPropertyType].name"
					>
						<el-checkbox :label="citem.id" v-for="(citem, ci) in item.optionMap" :key="ci">{{ citem.value }}</el-checkbox>
					</component>
					<component
						:placeholder="item.approvalPropertyTip"
						v-model="item.value"
						:name="'radio' + item.approvalInfoId"
						v-else-if="'el-radio-group' === ccomponents[item.approvalPropertyType].name"
						:is="ccomponents[item.approvalPropertyType].name"
					>
						<el-radio :label="citem.id" v-for="(citem, ci) in item.optionMap" :key="ci">{{ citem.value }}</el-radio>
					</component>
					<component
						v-else-if="ccomponents[item.approvalPropertyType].name === 'p'"
						:is="ccomponents[item.approvalPropertyType].name"
						class="form-item-tip"
						>{{ item.approvalPropertyDefaultValue }}</component
					>
					<component
						v-else-if="ccomponents[item.approvalPropertyType].name === 'el-upload'"
						multiple
						:action="ccomponents[item.approvalPropertyType].props.action"
						:limit="3"
						name="imgFile"
						:before-upload="handleBeforeUpload"
						:on-success="
							(res, file, filelist) => {
								handleUploaded(res, file, filelist, item);
							}
						"
						:on-remove="
							(file, filelist) => {
								handleRemoveFile(file, filelist, item);
							}
						"
						:file-list="item.fileList"
						:on-exceed="handleExceed"
						:is="ccomponents[item.approvalPropertyType].name"
					>
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">{{ item.approvalPropertyTip }}</div>
					</component>
					<div
						v-else-if="
							[Departments, Employees, Employees2, Employees3, PositionLevels].includes(ccomponents[item.approvalPropertyType].alias)
						"
					>
						<component
							v-if="ccomponents[item.approvalPropertyType].alias == '员工' || approvalType.subType != 9"
							@click="openInnerDialog(ccomponents[item.approvalPropertyType].alias, item)"
							type="text"
							:is="ccomponents[item.approvalPropertyType].name"
							>选择</component
						>
						<p>
							<el-tag :closable="approvalType.subType != 9" v-if="item.value && item.value.id" @close="removeTag(item)">{{
								item.value && item.value.value
							}}</el-tag>
						</p>
					</div>
					<component
						v-else-if="ccomponents[item.approvalPropertyType].name === 'el-select'"
						:is="ccomponents[item.approvalPropertyType].name"
						class="select-form-item"
						:placeholder="item.approvalPropertyTip"
						v-model="item.value"
						:disabled="approvalType.subType == 9"
					>
						<el-option v-for="(option, i) in item.optionMap" :label="option.value" :value="option.id" :key="i"></el-option>
					</component>
					<component
						:placeholder="item.approvalPropertyTip"
						:disabled="_approvalType9Input(item)"
						v-else
						class="input-form-item"
						style="width: 400px"
						:controls="false"
						v-model="item.value"
						v-bind="makeProps(item)"
						:is="ccomponents[item.approvalPropertyType].name"
					></component>
				</el-form-item>
			</div> -->
			<div class="sections-container">
				<h3 class="title-icon">审批流程</h3>
				<el-form-item prop="confirmNote" label="审批流" style="margin-bottom: 0">
					<div>
						<el-timeline>
							<el-timeline-item color="#409EFF" size="large">
								<div class="approvalConChild">
									<div class="approvalConChild_head">
										<span>发起人</span>
										<p style="color: #ccc">{{ userInfo.nickName || '' }}</p>
									</div>
								</div>
							</el-timeline-item>
							<el-timeline-item color="#409EFF" size="large">
								<div class="approvalConChild">
									<div class="approvalConChild_head">
										<span>审批人</span>
										<p style="color: #ccc">{{ approveStaffs.filter(({ isPolicy }) => !isPolicy).length }}人审批</p>
									</div>
									<div class="approvalConChild_container">
										<div
											@click="_openDialog(1, 'approveStaffs')"
											class="block"
											style="cursor: pointer"
											v-show="approveStaffs.filter(({ isPolicy }) => !isPolicy).length > 8"
										>
											<div>
												<div style="text-align: center">
													<img src="../../../../assets/approval/查看全部@3x.png" style="width: 38px" alt="" />
												</div>
												<p class="approvalConChild_container_name">查看全部</p>
											</div>
											<i class="el-icon-arrow-right"></i>
										</div>
										<div
											class="block"
											v-for="(item, index) in approveStaffs.filter(({ isPolicy }) => !isPolicy)"
											:key="index"
											v-show="index <= 8"
										>
											<div>
												<div style="position: relative; padding: 0 10px">
													<el-button
														@click="_deleteCCorStaff('approveStaffs', index, item)"
														v-if="modifyStatus == 1"
														style="background-color: #181f25; color: #fff"
														size="mini"
														class="delete"
														icon="el-icon-close"
														circle
													></el-button>
													<el-avatar :size="38" :src="item.avatarUrl || require('../../../../assets/job.png')"></el-avatar>
												</div>
												<p class="approvalConChild_container_name">{{ item.userName || item.staffName || item.jobName }}</p>
											</div>
											<i
												class="el-icon-arrow-right"
												v-if="index != approveStaffs.filter(({ isPolicy }) => !isPolicy).length - 1"
											></i>
										</div>
									</div>
									<div v-if="modifyStatus == 1">
										<el-button class="approvalConChild_add" icon="el-icon-plus" circle @click="_addCCorStaff(1)"></el-button>
									</div>
								</div>
							</el-timeline-item>
							<el-timeline-item color="#409EFF" size="large">
								<div class="approvalConChild">
									<div class="approvalConChild_head">
										<span>决策人</span>
										<p style="color: #ccc">{{ approveStaffs.filter(({ isPolicy }) => isPolicy).length }}人审批</p>
									</div>
									<div class="approvalConChild_container" style="margin-right: 0">
										<div
											@click="_openDialog(1, 'approveStaffs')"
											class="block"
											style="cursor: pointer"
											v-show="approveStaffs.filter(({ isPolicy }) => isPolicy).length > 8"
										>
											<div>
												<div style="text-align: center">
													<img src="../../../../assets/approval/查看全部@3x.png" style="width: 38px" alt="" />
												</div>
												<p class="approvalConChild_container_name">查看全部</p>
											</div>
											<i class="el-icon-arrow-right"></i>
										</div>
										<div
											class="block"
											v-for="(item, index) in approveStaffs.filter(({ isPolicy }) => isPolicy)"
											:key="index"
											v-show="index <= 8"
										>
											<div>
												<div style="position: relative; padding: 0 10px">
													<el-button
														v-if="modifyStatus == 1"
														style="background-color: #181f25; color: #fff"
														size="mini"
														class="delete"
														icon="el-icon-edit"
														circle
														@click="_addCCorStaff(1, 1)"
													></el-button>
													<el-avatar :size="38" :src="item.avatarUrl || require('../../../../assets/job.png')"></el-avatar>
												</div>
												<p class="approvalConChild_container_name">{{ item.userName || item.staffName || item.jobName }}</p>
											</div>
											<i
												class="el-icon-arrow-right"
												v-if="index != approveStaffs.filter(({ isPolicy }) => isPolicy).length - 1"
											></i>
										</div>
									</div>
									<!-- <div v-if="modifyStatus == 1">
										<el-button class="approvalConChild_add" icon="el-icon-plus" circle @click="_addCCorStaff(1, 1)"></el-button>
									</div> -->
								</div>
							</el-timeline-item>
							<el-timeline-item color="#409EFF" size="large">
								<div class="approvalConChild">
									<div class="approvalConChild_head">
										<span>抄送人</span>
										<p style="color: #ccc">抄送{{ ccStaffs.length }}人</p>
									</div>
									<div class="approvalConChild_container">
										<div @click="_openDialog(2, 'ccStaffs')" class="block" style="cursor: pointer" v-show="ccStaffs.length > 8">
											<div>
												<div style="text-align: center">
													<img src="../../../../assets/approval/查看全部@3x.png" style="width: 38px" alt="" />
												</div>
												<p class="approvalConChild_container_name">查看全部</p>
											</div>
											<i class="el-icon-arrow-right"></i>
										</div>
										<div class="block" v-for="(item, index) in ccStaffs" :key="index" v-show="index <= 8">
											<div>
												<div style="position: relative; padding: 0 10px">
													<el-button
														@click="_deleteCCorStaff('ccStaffs', index)"
														style="background-color: #181f25; color: #fff"
														size="mini"
														class="delete"
														icon="el-icon-close"
														circle
													></el-button>
													<el-avatar :size="38" :src="item.avatarUrl"></el-avatar>
												</div>
												<p class="approvalConChild_container_name">{{ item.userName || item.staffName }}</p>
											</div>
											<i class="el-icon-plus" v-if="index != ccStaffs.length - 1"></i>
										</div>
									</div>
									<div>
										<el-button class="approvalConChild_add" icon="el-icon-plus" circle @click="_addCCorStaff(2)"></el-button>
									</div>
								</div>
							</el-timeline-item>
						</el-timeline>
					</div>
				</el-form-item>
				<el-row style="text-align: center; margin-top: -10px">
					<el-button @click="toBack">取消</el-button>
					<el-button type="primary" @click="submit">提交</el-button>
				</el-row>
			</div>
		</el-form>
		<el-dialog
			append-to-body
			top="10px"
			:title="ccStatusType == 1 ? (isPolicy ? '选择决策人' : '选择审批人') : '选择抄送人'"
			:visible.sync="ccStatus"
		>
			<div style="height: 80vh">
				<table-employees
					v-if="ccStatus"
					:selectStatus="true"
					@confirm="_confirmSelect"
					:show="ccStatus"
					:filter-params="{}"
					@cancel="ccStatus = false"
				></table-employees>
			</div>
		</el-dialog>
		<el-dialog append-to-body top="10px" center :visible.sync="ccDialogStatus" class="morye">
			<span slot="title" class="dialog-title">{{ ccStatusType == 1 ? '审批人' : '抄送人' }}</span>
			<div style="height: 80vh">
				<div style="margin-bottom: 10px">{{ ccStatusType == 1 ? `${cpStaffs.length}人依次审批` : `向${cpStaffs.length}人抄送` }}</div>
				<el-row>
					<div v-for="(item, index) in cpStaffs" :key="index" style="display: inline-block; margin-bottom: 20px">
						<div class="block">
							<div>
								<div style="position: relative; padding: 0 10px">
									<el-button
										@click="_deleteCCorStaff('cpStaffs', index)"
										v-if="modifyStatus == 1 || ccStatusType != 1"
										style="background-color: #181f25; color: #fff"
										size="mini"
										class="delete"
										icon="el-icon-close"
										circle
									></el-button>
									<el-avatar :size="38" :src="item.avatarUrl"></el-avatar>
								</div>
								<p class="approvalConChild_container_name">{{ item.userName || item.staffName }}</p>
							</div>
							<i :class="ccStatusType == 1 ? 'el-icon-arrow-right' : 'el-icon-plus'" style="margin-top: -8px"></i>
						</div>
					</div>
					<div style="display: inline-block; width: 58px; text-align: center; position: relative; top: -10px">
						<el-button class="approvalConChild_add" icon="el-icon-plus" circle @click="_addCCorStaff(ccStatusType)"></el-button>
					</div>
				</el-row>
			</div>
			<span slot="footer" class="dialog-footer" style="text-align: center">
				<el-button @click="ccDialogStatus = false" size="medium">取消</el-button>
				<el-button type="primary" size="medium" @click="_saveCpStaffs">保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import TableEmployees from '../../components/table-employees';
import { getApprovalProperties, getApprovalType, approvalGetUserInfo, getApprovalDetails } from '../../../../api/staffSystem';
import { getProcessDetail } from '@/api/audit';
import { Mode } from '../../const';
import { FileUploadAction } from '../../../../utils/const';
import { getStore, setStore } from '@/utils/util';
const Employees = '员工';
const Employees2 = '投递者';
const Employees3 = '调薪';
const PositionLevels = '职级体系';
const Departments = '组织';

export default {
	name: 'orgSubmit',
	components: {
		TableEmployees
	},
	props: { staffId: { type: [Number, String] } },
	data() {
		return {
			mode: this.$route.query.mode,
			Mode,
			Employees,
			Employees2,
			Employees3,
			PositionLevels,
			Departments,
			labelWidth: 4,
			approvalInfoName: '',
			approvalTypeId: '',
			approvalTypes: [],
			approvalType: null,
			dialogAction: '',
			dialogVisible: false,
			loading: false,
			innerDialogAction: '',
			properties: [],
			submitting: false,
			uploadQueue: [],
			activeDialogObj: null,
			resumeId: '', // 入职投递者ID
			ccomponents: {
				1: {
					name: 'el-input',
					props: {
						maxlength: 20
					}
				},
				2: {
					name: 'el-input',
					props: {
						rows: 4,
						maxlength: 200,
						type: 'textarea'
					}
				},
				3: {
					name: 'el-date-picker',
					props: {
						type: 'date',
						format: 'yyyy-MM-dd',
						valueFormat: 'yyyy-MM-dd'
					}
				},
				4: {
					name: 'el-date-picker',
					multiple: true,
					props: {
						type: 'daterange',
						format: 'yyyy-MM-dd',
						valueFormat: 'yyyy-MM-dd'
					}
				},
				5: {
					name: 'el-radio-group',
					options: true,
					props: {}
				},
				6: {
					name: 'el-checkbox-group',
					options: true,
					multiple: true,
					props: {
						multiple: true
					}
				},
				7: {
					name: 'el-upload',
					multiple: true,
					files: true,
					props: {
						action: FileUploadAction
					}
				},
				8: {
					name: 'el-input-number',
					props: {
						min: 0,
						max: 10000000
					}
				},
				9: {
					name: 'el-date-picker',
					props: {
						type: 'date',
						format: 'yyyy-MM',
						valueFormat: 'yyyy-MM'
					}
				},
				10: {
					name: 'el-select',
					options: true
				},
				11: {
					name: 'el-date-picker',
					props: {
						type: 'year',
						format: 'yyyy',
						valueFormat: 'yyyy'
					}
				},
				101: {
					name: 'el-button',
					alias: Employees,
					single: true,
					props: {}
				},
				102: {
					name: 'el-button',
					alias: PositionLevels,
					single: true,
					props: {}
				},
				103: {
					name: 'el-button',
					single: true,
					alias: Departments,
					props: {}
				},
				104: {
					// 入职 职级体系
					name: 'el-button',
					alias: PositionLevels,
					single: true,
					props: {}
				},
				105: {
					// 调薪 员工
					name: 'el-button',
					alias: Employees,
					single: true,
					props: {}
				},
				106: {
					// 入职 投递者
					name: 'el-button',
					alias: Employees2,
					single: true,
					props: {}
				}
			},
			form: {},
			rules: {},
			contrachLoading: false,
			dateRange: '',
			approveStaffs: [], // 新增审批集合
			ccStaffs: [], // 新增抄送人集合
			cpStaffs: [], // 弹窗审批或抄送人列表
			ccStatusType: '',
			ccStatus: false, //
			modifyStatus: 0, // 是否能删除增加审批人;1能，0不能
			ccDialogStatus: false,
			firstTimeIn: false,
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() < new Date(new Date().toLocaleDateString()).getTime();
				}
			}
		};
	},
	computed: {
		query() {
			return this.$route.query;
		},
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		},
		// 入职
		isEntry() {
			return this.query.subType === '8';
		},
		// 编辑
		isEdit() {
			return Boolean(this.query.applyId);
		}
	},
	methods: {
		_dateRangeChange(val) {
			this.form.assessStartTime = val ? val[0] : '';
			this.form.assessEndTime = val ? val[1] : '';
		},
		// _getApprovalTypeList() {
		//   getApprovalType().then(res => {
		//     this.approvalTypes = res;
		//   });
		// },
		onUploadChange(file) {
			const isLt50M = file.size / 1024 / 1024 < 5;

			if (!isLt50M) {
				this.$alert('大小不得超过5M');
				return false;
			}
			this.fileSize = this.renderSize(file.size);
			const formData = new FormData();

			formData.append('imgFile', file.raw);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.contrachLoading = true;
			this.$axios
				.post('/v1/common/upload/file', formData, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					this.form.assessFileUrl = [
						{
							name: res.fileName,
							size: '',
							url: res.httpUrl,
							bucketName: res.bucketName,
							key: res.key
						}
					];
					this.contrachLoading = false;
				});
		},
		makeProps(item) {
			if (item.props) {
				return {
					...this.ccomponents[item.approvalPropertyType].props,
					...item.props
				};
			}
			return this.ccomponents[item.approvalPropertyType].props;
		},
		async getApprovalType() {
			return getApprovalType().then((res) => {
				if (res._responseStatusCode === 0) {
					this.approvalTypes = res;
				}
			});
		},
		handleExceed(files, filelist) {
			if (files.length > 3 || filelist.length === 3 || files.length + filelist.length > 3) {
				this.$message({
					message: '上传文件数量不能超过三个',
					type: 'warning'
				});
			}
		},
		handleUploaded(res, file, filelist, item) {
			this.uploadQueue.shift();
			if (res.code === 0) {
				this.$notify({
					type: 'success',
					message: '上传成功'
				});
				const resFileMeta = {
					name: res.data.fileName,
					size: file.size,
					url: res.data.httpUrl,
					bucketName: res.data.bucketName,
					key: res.data.key
				};

				item.value.push(resFileMeta);
			} else {
				this.$notify({
					type: 'error',
					message: '上传失败'
				});
				filelist.pop();
			}
		},
		handleBeforeUpload(file) {
			this.uploadQueue.push(1);
			return file;
		},
		handleRemoveFile(file, filelist, item) {
			if (file.status === 'uploading') {
				this.uploadQueue.pop();
			}
			let fileKey = '';

			if (file.response) {
				fileKey = file.response.data.key;
			} else {
				fileKey = file.key;
			}

			const index = item.value.findIndex((v) => v.key === fileKey);

			if (index !== -1) {
				item.value.splice(index, 1);
			}
		},
		confirmSelect(value) {
			if (value.length > 0) {
				if (this.approvalType.subType == '3' || this.approvalType.subType == '6') {
					if (this.$store.state.app.range.staffIds.includes(value[0].id)) {
						this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
						return;
					}
					if (this.$store.state.app.range.performanceEmployeesIds.includes(value[0].id)) {
						this.$message.warning('当前员工绩效考核进行中，请结束后再次申请');
						return;
					}
				}
				if (this.approvalType.subType == 8) {
					// 入职
					this.resumeId = value[0].id;
				}
				this.activeDialogObj.value = value[0];
				this.innerDialogVisible = false;
			}
		},
		confirmSelect2(value) {
			if (value.length > 0) {
				if (this.approvalType.subType == '3' || this.approvalType.subType == '6') {
					if (this.$store.state.app.range.staffIds.includes(value[0].id)) {
						this.$message.warning('当前员工人岗匹配进行中，请结束后再次申请');
						return;
					}
					if (this.$store.state.app.range.performanceEmployeesIds.includes(value[0].id)) {
						this.$message.warning('当前员工绩效考核进行中，请结束后再次申请');
						return;
					}
				}
				if (this.approvalType.subType == 8) {
					// 入职
					this.resumeId = value[0].id;
					this.properties[1].value = value[0].resumeInterviewTime;
				}
				this.activeDialogObj.value = value[0];
				this.innerDialogVisible = false;
				if (this.approvalType.subType == 9) {
					// 调薪
					approvalGetUserInfo({ staffId: value[0].id }).then((res) => {
						for (const key in this.properties) {
							if (
								this.properties[key].approvalPropertyKey == 'jobEtc' ||
								this.properties[key].approvalPropertyKey == 'jobEtc' ||
								this.properties[key].approvalPropertyKey == 'performanceWageBefore' ||
								this.properties[key].approvalPropertyKey == 'baseWageBefore' ||
								this.properties[key].approvalPropertyKey == 'postWageBefore'
							) {
								this.properties[key].value = res[this.properties[key].approvalPropertyKey];
							} else if (this.properties[key].approvalPropertyKey == 'newOrgId') {
								this.properties[key].value = { id: res.orgId, value: res.orgName };
							} else if (this.properties[key].approvalPropertyKey == 'newLevelAndCodeId') {
								this.properties[key].value = { id: res.levelAndCodeId, value: res.levelAndCodeValue };
							}
						}
						this.$forceUpdate();
					});
				}
			}
		},
		_confirmSelect(value) {
			// 抄送人或审批人
			if (!value[0]) {
				this.$message.error('未选择员工');
				return false;
			}
			const _obj = value[0];

			if (this.ccDialogStatus) {
				// 选择重复
				if (this.cpStaffs.filter(({ staffId }) => Number(staffId) === Number(_obj.staffId))?.length) {
					this.$message.warning('重复选择');
					return;
				}
				this.cpStaffs.push(_obj);
			} else if (this.ccStatusType == 1) {
				// 选择重复
				if (this.approveStaffs.filter(({ staffId }) => Number(staffId) === Number(_obj.staffId))?.length) {
					this.$message.warning('重复选择');
					return;
				}
				if (this.isPolicy) {
					this.approveStaffs = this.approveStaffs.filter(({ isPolicy }) => !isPolicy).concat({ ..._obj, isPolicy: this.isPolicy });
					this.isPolicy = null;
				} else {
					this.approveStaffs.push({ ..._obj });
				}
			} else {
				// 选择重复
				if (this.ccStaffs.filter(({ staffId }) => Number(staffId) === Number(_obj.staffId))?.length) {
					this.$message.warning('重复选择');
					return;
				}
				this.ccStaffs.push(_obj);
			}
		},
		_addCCorStaff(type, isPolicy) {
			// 添加抄送人或审批人
			this.ccStatusType = type;
			this.ccStatus = true;
			this.isPolicy = isPolicy;
		},
		_openDialog(type, listName) {
			// 打开查看全部
			this.ccStatusType = type;
			this.cpStaffs = JSON.parse(JSON.stringify(this[listName]));
			this.ccDialogStatus = true;
		},
		_deleteCCorStaff(listName, index, item) {
			// 删除抄送人或审批人
			// if (
			// 	(listName == 'approveStaffs' && this[listName].length - 1 == 0) ||
			// 	(listName == 'cpStaffs' && this.ccStatusType == 1 && this[listName].length - 1 == 0)
			// ) {
			// 	this.$message.error('审批人至少一个');
			// 	return;
			// }
			if (listName === 'approveStaffs') {
				this[listName] = this[listName].filter(({ staffId }) => staffId != item.staffId);
				return;
			}
			this[listName].splice(index, 1);
		},
		_saveCpStaffs() {
			if (this.ccStatusType == 1) {
				this.approveStaffs = [...this.cpStaffs.filter(({ isPolicy }) => !isPolicy), ...this.approveStaffs.filter(({ isPolicy }) => isPolicy)];
			} else {
				this.ccStaffs = [...this.cpStaffs];
			}
			// this.ccStatusType == 1 ? (this.approveStaffs = [...this.cpStaffs]) : (this.ccStaffs = [...this.cpStaffs]);
			this.ccDialogStatus = false;
		},
		removeTag(item) {
			this.$set(item, 'value', { id: '', value: '' });
		},
		async getApprovalProperties(id) {
			this.loading = true;
			this.properties = [];
			return getApprovalProperties({
				approvalInfoId: id,
				approvalPropertyPosition: '1'
			}).then((res) => {
				this.loading = false;
				if (res._responseStatusCode === 0) {
					this.properties = res;
					this.properties.forEach((v) => {
						if (v.approvalPropertyName.length > this.labelWidth) {
							this.labelWidth = v.approvalPropertyName.length;
						}
						if (v.approvalPropertyName === '转正评价') {
							v.props = {};
							v.props.maxlength = 300;
						}
						this.setBlankValue(v);
						if (this.ccomponents[v.approvalPropertyType].options) {
							const optionArr = JSON.parse(v.approvalPropertyDefaultValue) || [];

							if (optionArr.length > 0 && v.approvalPropertyName === '奖惩类型') {
								const result = this.findApprovalType(id);

								if (result.approvalName === '奖惩') {
									this.$set(v, 'value', optionArr[0].id);
								}
							}
							v.optionMap = {};
							optionArr.forEach((cv) => {
								v.optionMap[cv.id] = cv;
							});
						}
					});
					if (this.firstTimeIn) {
						this.firstTimeIn = false;
						// console.log(this.properties)
					}
					return true;
				}
			});
		},
		cancel() {
			this.dialogVisible = false;
			this.$emit('update:visible', false);
		},
		toBack() {
			if (this.isEntry) {
				return this.$emit('cancel');
			}
			this.$router.go(-1);
		},
		findApprovalType(id) {
			const result = this.approvalTypes.find((v) => v.approvalId === id);

			return result;
		},
		async submit() {
			if (!this.query.approvalId) {
				this.$message({
					message: '请选择审批类型',
					type: 'warning'
				});
				return;
			}

			const _ccStaffIds = [];

			// eslint-disable-next-line guard-for-in
			for (const mkey2 in this.ccStaffs) {
				_ccStaffIds.push(this.ccStaffs[mkey2].staffId);
			}

			let oldList = getStore(`orgRecords_${this.query.approvalId}`);

			if (oldList) {
				oldList = JSON.parse(oldList);
			} else {
				oldList = [];
			}

			const params = {
				approvalInfoId: this.query.approvalId,
				// approvalApplyValueList,
				approvalProcessDtoList: this.approveStaffs,
				ccStaffIds: _ccStaffIds
			};

			// 组织申请
			if (this.query.subType === '11') {
				params.orgRecords = oldList.map((val) => {
					let str = '';

					switch (val.changeType) {
						case 'move':
							str = `组织 "${val.orgName}" 移动到 "${val.orgPdata?.orgName}" 下`;
							break;
						case 'delete':
							str = `删除 "${val.orgName}" 组织`;
							break;
						case 'add':
							str = `"${val.orgPname || ''}" 新增组织 "${val.orgName}"`;
							break;
						case 'edit':
							str = `组织 "${val?.orginData?.['orgName']}"`;

							['orgName', 'orgCode', 'cityName'].forEach((key) => {
								if (val[key] !== val?.orginData?.[key] && val[key] && val?.orginData?.[key]) {
									switch (key) {
										case 'orgName':
											str += ` 名称变更为 "${val[key]}"；`;
											break;
										case 'orgCode':
											str += ` 编码 "${val?.orginData?.[key]}" 变更为 "${val[key]}"；`;
											break;
										case 'cityName':
											str += `城市 "${val?.orginData?.[key]}" 变更为 "${val[key]}"；`;
											break;

										default:
											break;
									}
								}
							});
							break;

						default:
							break;
					}
					return str;
				});
			}

			if (this.isEntry) {
				params.businessId = this.staffId;
			}
			this.submitting = true;
			this.loading = true;
			if (this.isEdit) {
				params.applyId = this.query.applyId;

				// 编辑
				this.$axios
					.post('/v1/approval/again/save', { request: params })
					.then((res) => {
						this.submitting = false;
						this.loading = false;
						if (res._responseStatusCode === 0) {
							// setStore(`orgRecords_${this.query.approvalId}`, '');
							this.$message({
								type: 'success',
								message: '提交成功'
							});
							this.$router.push({
								path: '/staff/approval'
							});
						}
					})
					.catch(() => {});
			} else {
				// 新增
				this.$axios
					.post('/v1/approval/user/apply', { request: params })
					.then((res) => {
						this.submitting = false;
						this.loading = false;
						if (res._responseStatusCode === 0) {
							setStore(`orgRecords_${this.query.approvalId}`, '');
							this.$message({
								type: 'success',
								message: '提交成功'
							});
							this.$router.push({
								path: '/staff/approval'
							});
						}
					})
					.catch(() => {});
			}
		},
		setBlankValue(item) {
			if (this.ccomponents[item.approvalPropertyType].multiple) {
				this.$set(item, 'value', []);
			} else if (!this.ccomponents[item.approvalPropertyType].options) {
				this.$set(item, 'value', item.approvalPropertyDefaultValue || '');
			}
			if (this.ccomponents[item.approvalPropertyType].files) {
				this.$set(item, 'fileList', []);
			}
		},
		_getProcessDetail(id) {
			getProcessDetail({ request: { approvalInfoId: id } }).then((res) => {
				this.modifyStatus = res[0].modifyStatus ? res[0].modifyStatus : 0;
				const _list = [];

				for (const key in res) {
					if (key != '_responseStatusCode') {
						_list.push(res[key]);
					}
				}
				this.approveStaffs = _list;
			});
		},
		_getApprovalDetails() {
			getApprovalDetails({ id: this.$route.query.applyId }).then((res) => {
				if (res._responseStatusCode === 0) {
					this.approvalType = { approvalId: res.approvalId, subType: res.subType, approvalName: res.subTypeName };
					const _list = [];

					// eslint-disable-next-line guard-for-in
					for (const key in res.approvalProcessList) {
						_list.push({
							staffId: res.approvalProcessList[key].staffId,
							userName: res.approvalProcessList[key].userName,
							avatarUrl: res.approvalProcessList[key].avatarUrl
						});
					}
					const _cclist = [];

					// eslint-disable-next-line guard-for-in
					for (const keys in res.ccList) {
						_cclist.push({
							staffId: res.ccList[keys].userId,
							userName: res.ccList[keys].userName,
							avatarUrl: res.ccList[keys].avatarUrl
						});
					}
					this.ccStaffs = _cclist;
					this.approveStaffs = _list;
					// this.changeApprovalProperties(this.approvalType);
					// this.getApplyApproval(this.$route.query.applyId);
				}
			});
		},
		_approvalType9Input(item) {
			if (
				(this.approvalType.subType == 9 && item.approvalPropertyKey == 'baseWageBefore') ||
				item.approvalPropertyKey == 'postWageBefore' ||
				item.approvalPropertyKey == 'performanceWageBefore'
			) {
				return true;
			}
			return false;
		}
	},
	mounted() {
		this.getApprovalType();
		if (this.query.approvalId) {
			this._getProcessDetail(this.query.approvalId);
		}
		// eslint-disable-next-line no-unused-expressions
		Number(this.$route.query.applyId) ? this._getApprovalDetails() : '';
	}
};
</script>
<style scoped lang="scss">
.sections-container {
	border-radius: 4px;
	padding: 27px 30px;
	background-color: #fff;
	overflow: hidden;
	margin-bottom: 10px;
	.title-icon {
		font-weight: bold;
		font-size: 16px;
		margin: 0 0 20px 10px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
	.section-wrap {
		margin-bottom: 20px;
	}
}
.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin-bottom: 20px;
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}
.applay {
	.el-dialog__header {
		text-align: center;
	}
}
.approvalConChild {
	flex: 1;
	display: flex;
	flex-direction: row;
	// border-bottom: 1px solid #EAEDF1;;
	padding-bottom: 15px;
	.approvalConChild_head {
		margin-top: -12px;
	}
	.approvalConChild_container {
		flex: 1;
		width: 100%;
		margin-right: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-start;
		flex-wrap: nowrap;
		white-space: nowrap;
	}
}
.morye,
.approvalConChild_container {
	.block {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		.approvalConChild_container_name {
			line-height: 14px;
			color: #c8c8c8;
			font-size: 14px;
		}
		.delete {
			position: absolute;
			top: -1px;
			right: 0;
			padding: 2px;
		}
		p {
			text-align: center;
		}
		i.el-icon-arrow-right {
			color: #c5cdda;
			font-size: 14px;
			margin-top: -30px;
		}
		i.el-icon-plus {
			color: #c5cdda;
			font-size: 14px;
			margin-top: -30px;
		}
	}
}
.approvalConChild_add {
	border: 1px dashed #409eff;
	color: #409eff;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
.morye {
	::v-deep.el-dialog__header {
		text-align: center;
		// border-bottom: 1px solid #EAEDF1;
	}
	::v-deep.el-dialog__footer {
		text-align: center;
	}
	.dialog-title {
		font-family: PingFangSC-Medium;
		font-size: 21px;
		color: #409eff;
		letter-spacing: 0;
		line-height: 24px;
	}
}
</style>
