<template>
  <div class="timeLine">
    <div v-for="(item,index) in data"
         :key="index">
      <div v-if="index+1 == 1">
        <div>
          <svg-icon class="svg"
                    icon-class="s1"></svg-icon>
        </div>
        <div class="time"
             style="top: 2px;left: 155px;">
          <span class="bigCricle"
                :style="{background:item.color}">{{data.length - index}}</span>
          <div class="dateWarp">
            <span class="date"
                  :style="{color:item.color}">{{item.time}}</span>
            <span class="line"
                  :style="{background:item.color}"></span>
          </div>
          <span class="smallCricle"
                :style="{borderColor:item.color}"></span>
          <span class="desc"
                style="text-align: left;padding-left: 30px; left:155px;line-height:1.5em;"
                v-html="item.remark"></span>
        </div>
      </div>
      <div v-if="(index+1) % 2 == 0">
        <div v-if="index+1 == data.length"
             style="margin-top: -9.5px;margin-left: 40px;">
          <svg-icon class="svg  my-svg"
                    icon-class="s4"></svg-icon>
        </div>
        <div v-if="index+1 != data.length"
             style="margin-top: -9.5px;margin-left: 69px;">
          <svg-icon class="svg my-svg"
                    icon-class="s2"></svg-icon>
        </div>
        <div class="time"
             :style="{top:item.top,left:item.left}">
          <span class="desc leftd"
                v-html="item.remark"></span>
          <span class="smallCricle"
                :style="{borderColor:item.color}"></span>
          <div class="dateWarp">
            <span class="date"
                  :style="{color:item.color}">{{item.time}}</span>
            <span class="line"
                  :style="{background:item.color}"></span>
          </div>
          <span class="bigCricle"
                :style="{background:item.color}">{{data.length -index}}</span>
        </div>
      </div>
      <div v-if="(index+1) % 2 != 0 && index+1 !=1">
        <div style="margin-top: -9.5px;margin-left: -20px;">
          <svg-icon style="margin-left: 22px;"
                    v-if="index+1 == data.length"
                    class="svg "
                    icon-class="s5"></svg-icon>
          <svg-icon v-if="index+1 != data.length"
                    class="svg my-svg"
                    icon-class="s3"></svg-icon>
        </div>
        <div class="time"
             :style="{top:item.top,left:item.left}">
          <span class="bigCricle"
                :style="{background:item.color}">{{data.length -index}}</span>
          <div class="dateWarp">
            <span class="date"
                  :style="{color:item.color}">{{item.time}}</span>
            <span class="line"
                  :style="{background:item.color}"></span>
          </div>
          <span class="smallCricle"
                :style="{borderColor:item.color}"></span>
          <span class="desc"
                style="text-align: left;padding-left: 30px;left:155px;padding: 0 10px 0 30px;;line-height:20px"
                v-html="item.remark"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    growthRecord: {
      type: Array
    }
  },
  watch: {
    growthRecord: {
      handler (newVal) {
        this.data = newVal;
        let top = 0;

        for (let i = 0; i < this.data.length; i++) {
          if (i == 0) {
            this.data[i].top = '0px';
          } else if (i + 1 == this.data.length) {
            top += 65.1;
            this.data[i].top = `${top}px`;
          } else {
            top += 65.5;
            this.data[i].top = `${top}px`;
          }
          if (i + 1 == this.data.length) {
            this.data[i].top = `${top - 5.7}px`;
          } else if (i !== 0) {
            this.data[i].top = `${top - 1.8}px`;
          }
          if ((i + 1) % 2 == 0) {
            this.data[i].left = '70px';
          } else {
            this.data[i].left = '155px';
            if (i + 1 === this.data.length) {
              this.data[i].top = `${parseFloat(this.data[i].top) - 0.2}px`;
            }
          }
          this.data[i].color = this.makeGrowthTypeColor(this.data[i].growthTypeName);
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      growthTypeMap: {},
      data: [
        // {
        //   'time': 2019555,
        //   "top": '0',
        //   left: '155px',
        //   color: '#FC6040 '
        // },
        // {
        //   'time': 20100555,
        //   "top": '63px',
        //   left: '70px',
        //   color: '#D8D8D8'
        // },
        // {
        //   'time': 2010555,
        //   "top": '128px',
        //   left: '155px',
        //   color: '#7ED321'
        // },
        // {
        //   'time': 2010055,
        //   "top": '190px',
        //   left: '70px',
        //   color: '#498DF0'
        // }
      ],
      colors: ['#498DF0', '#7ED321', '#D8D8D8', '#FC6040']
    };
  },
  mounted () {
    this.$nextTick(() => {
    });
  },
  methods: {
    makeGrowthTypeColor (type) {
      switch (type) {
        case '惩罚':
          return '#FC6040';
        case '奖励':
          return '#7ED321';
        case '晋升':
          return '#7ED321';
        default:
          return '#498DF0';
      }

    }
  }
};
</script>
<style lang="scss" scoped>
.timeLine {
  width: 400px;
  // min-height: 300px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.ml {
  margin-left: 200px;
}
.time {
  width: 200px;
  height: 80px;
  display: flex;
  align-items: center;

  justify-content: center;
  position: absolute;
  //   margin: 0 auto;
  .bigCricle {
    width: 44px;
    height: 44px;
    background: #498df0;
    border-radius: 50%;
    padding: 10px;
    color: #fff;
    line-height: 23px;
  }
  .dateWarp {
    width: 90px;
    font-size: 12px;
    color: #498df0;
    text-align: center;
  }
  .date {
    display: inline-block;
    margin-bottom: 3px;
  }
  .line {
    width: 90px;
    position: relative;
    height: 4px;
    background: #498df0;
    display: inline-block;
    margin-bottom: 13px;
  }
  .smallCricle {
    width: 10px;
    height: 10px;
    background: #ffffff;
    top: 0.8px;
    position: relative;
    border-radius: 50%;
    border: 2px solid #498df0;
    display: inline-block;
  }

  .desc {
    // min-width: 520px;
    // max-width: 580px;
    font-size: 14px;
    display: block;
    width: 60vh;
    text-align: justify;
    position: absolute;
  }
  .leftd {
    text-align: right;
    padding-right: 30px;
    left:-54.5vh;
    line-height:1.5em;
  }
  @media (min-width: 1300px){
    left: -51vh;
  }
}
.svg {
  width: 75px;
  height: 75px;
  &.my-svg {
    position: relative;
    left: -2px;
    top: 1px;
  }
}
</style>
