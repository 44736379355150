<!--  组织职能  -->
<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			@rowClick="rowClick"
			showOrder
		/>
		<!-- 新增组织职能 -->
		<el-dialog
			:title="dialogTitle"
			top="30vh"
			width="900px"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<baseTable
				v-if="formType === 'view'"
				:dataList="viewData"
				:columns="viewColumns"
				:showPagination="false"
				:spanMethod="spanMethod"
				v-loading="formLoading"
			/>
			<formItem
				v-else
				ref="formItem"
				style="margin: 20px 120px"
				:formItems="dialogItems"
				@submit="submitForm"
				@cancel="dialogVisible = false"
				:defaultData="formModel"
				:type="'edit'"
				v-loading="formLoading"
			>
				<div v-for="(item, inx) in dialogList" :key="inx" style="padding: 20px; border: 1px dashed #bfbfbf; margin-bottom: 10px">
					<formItem
						ref="formItem2"
						:formItems="dialogItems2"
						@cancel="dialogVisible = false"
						:defaultData="item"
						:type="'edit'"
						:showFooter="false"
					/>
					<div style="text-align: center; cursor: pointer" v-if="dialogList.length > 1">
						<i class="el-icon-delete" style="color: red" @click="dialogList.splice(inx, 1)"></i>
					</div>
				</div>
				<div class="addFunctions" @click="dialogList.push({})"><i class="el-icon-plus" style="margin-right: 4px"></i>添加职能</div>
			</formItem>
		</el-dialog>
		<!-- 导入按钮的弹窗 -->
		<Upload :visible.sync="dialogImportVisible" :config="uploadConfig" />
	</div>
</template>

<script>
/* 组织职能 */
import baseTable from '@/views/components/components-base-table';
import Upload from '@/components/Upload/index.vue';
import formItem from '@/views/components/components-form-item/index.vue';
import { downloadByCreateA } from '@/utils/util';
// import moment from 'moment';
export default {
	name: 'organizationalFunctions',
	components: {
		baseTable,
		Upload,
		formItem
	},

	props: {
		// 审批申请页面
		isApproval: {
			type: Boolean
			// required: true
		},
		pageName: {}
	},
	computed: {},
	mounted() {
		console.log(this.pageName, 'this.pageName');
	},
	data() {
		return {
			tableCols: [
				{ label: '组织名称', key: 'orgName' },
				{ label: '一级职能', key: 'functionDesc' },
				{ label: '二级职能', key: 'groupFunctionDesc' },
				{
					label: '操作',
					key: 'cz',
					render: (row) => (
						<div>
							{(this.isApproval || this.permissionControlBtns(this.pageName, 'Edit')) && (
								<el-button type="text" onclick={(e) => this.handleEdit(row, e)}>
									编辑
								</el-button>
							)}
							{(this.isApproval || this.permissionControlBtns(this.pageName, 'Delete')) && (
								<el-button
									type="text"
									style={'color:rgb(245, 108, 108); '}
									// disabled={this.getDelDisabled(row, inx)}
									onclick={(e) => this.handleDel(row, e)}
								>
									删除
								</el-button>
							)}
						</div>
					)
				}
			],
			queryItem: [
				{
					label: '组织',
					key: 'orgId',
					type: 'tree',
					async: () => this.$axios.post('/v1/org/list', { request: {} }),
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					span: 5,
					filter: true
				}
				// {
				// 	label: '组织级别',
				// 	key: 'orgLevel',
				// 	type: 'input',
				// 	span: 5
				// }
			],

			otherBtns: [
				(this.isApproval || this.permissionControlBtns(this.pageName, 'Add')) && {
					label: '新增组织职能',
					icon: 'el-icon-plus',
					style: { float: 'right' },
					click: () => {
						this.dialogTitle = '新增组织职能';
						this.formType = 'edit';
						this.formModel = {};
						this.dialogList = [{}];
						this.dialogVisible = true;
					}
				},
				this.permissionControlBtns(this.pageName, 'Export') && {
					label: '导出',
					icon: 'el-icon-download',
					style: { float: 'right' },
					click: (data, { orgName }) => {
						downloadByCreateA(`/v1/org/exportOrgFunction?params=${encodeURIComponent(JSON.stringify({ orgName }))}`);
					}
				},
				this.permissionControlBtns(this.pageName, 'Import') && {
					label: '导入',
					icon: 'el-icon-upload',
					style: { float: 'right' },
					click: () => {
						this.dialogImportVisible = true;
					}
				}
			].filter((v) => v),
			dialogVisible: false,
			formModel: {},
			dialogList: [{}],
			dialogItems: [
				{
					label: '组织',
					key: 'orgId',
					type: 'tree',
					options: this.$store.state.setData?.options?.orgId || [],
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					// span: 12,
					filter: true,
					rules: { required: true, message: '请选择', trigger: 'change' }
				},
				{
					label: '一级职能',
					key: 'functionsOne',
					type: 'input',
					rules: { required: true, message: '请输入', trigger: 'change' }
				}
			],
			dialogItems2: [
				{
					label: '二级职能',
					key: 'functionsTwo',
					type: 'input'
				},
				{
					label: '三级职能',
					key: 'functionsThree',
					type: 'empty',
					style: 'position: absolute;top:150px'
				},
				{
					label: '计划/规划',
					key: 'plan',
					type: 'input',
					labelWidth: '200px'
				},
				{
					label: '组织/实施',
					key: 'enforce',
					type: 'input',
					labelWidth: '200px'
				},
				{
					label: '协调/配合',
					key: 'cohere',
					type: 'input',
					labelWidth: '200px'
				},
				{
					label: '指导/监控',
					key: 'monitor',
					type: 'input',
					labelWidth: '200px'
				}
				// {
				// 	// width: 460,
				// 	render: (row, inx) => (
				// 		<div style="display:inline-flex;align-items:center;width:100%;color:#409EFF">
				// 			<i class="el-icon-plus" style="margin-right:4px"></i>添加职能
				// 		</div>
				// 	)
				// }
			],
			viewData: [],
			viewColumns: [
				{ label: '一级职能', key: 'functionDesc1' },
				{
					label: '二级职能',
					key: 'functionsTwo',
					render: (e) => {
						if ([2, 3].includes(e.functionLevel)) {
							return <span>{e.functionDesc}</span>;
						}
						return <div></div>;
					}
				},
				{
					label: '三级职能',
					key: 'functionsThree',
					children: [
						{
							label: '计划/规划',
							key: 'plan'
						},
						{
							label: '组织/实施',
							key: 'enforce'
						},
						{
							label: '协调/配合',
							key: 'cohere'
						},
						{
							label: '指导/监控',
							key: 'monitor'
						}
					]
				}
			],
			dialogTitle: '添加',
			formType: 'edit',
			dataList: [{}],
			// 导入配置
			uploadConfig: {
				dictId: 'importOrgFunctionTemplate',
				groupId: 'importTemplate',
				importUrl: '/v1/org/importOrgFunction',
				successMsg: '导入成功',
				callBack: () => {
					this.$refs.baseTable?.handleSearch();
					this.dialogImportVisible = false;
				}
			},
			dialogImportVisible: false,
			formLoading: false
		};
	},
	created() {},
	methods: {
		// 接口区分
		getUrlByApproval(type) {
			let url = '';
			const isApp = this.isApproval;

			switch (type) {
				case 'list':
					url = isApp ? '/v1/org/orgFunctionTempListByPage' : '/v1/org/orgFunctionListByPage';
					break;
				case 'delete':
					url = isApp ? '/v1/org/delOrgFunctionTempById' : '/v1/org/delOrgFunctionById';
					break;
				case 'edit':
					url = isApp ? '/v1/org/saveOrgFunctionTemp' : '/v1/org/saveOrgFunction';
					break;
				case 'detail':
					url = isApp ? '/v1/org/findOrgFunctionTempByOrgId' : '/v1/org/findOrgFunctionByOrgId';
					break;

				default:
					break;
			}
			return url;
		},
		getTableData(params, callback) {
			callback(this.$axios.post(this.getUrlByApproval('list'), params));
		},
		// 编辑
		async handleEdit(row, e) {
			e.stopPropagation();
			this.formType = 'edit';
			this.dialogTitle = '编辑';
			this.dialogVisible = true;
			this.formLoading = true;
			const res = await this.getViewDetail(row);

			this.formLoading = false;
			this.formModel = { id: res.id, orgId: row.orgId, orgName: row.orgName, functionsOne: row.functionDesc };
			this.dialogList = res.orgFunctions.map((val) => {
				const [plan, enforce, cohere, monitor] = [
					val.orgFunctions[0]?.functionDesc,
					val.orgFunctions[1]?.functionDesc,
					val.orgFunctions[2]?.functionDesc,
					val.orgFunctions[3]?.functionDesc
				];

				return { ...val, functionsTwo: val.functionDesc, cohere, enforce, monitor, plan };
			});
		},
		// 删除
		handleDel(row, e) {
			e.stopPropagation();
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post(this.getUrlByApproval('delete'), {
						request: { orgId: row.orgId },
						sign: ''
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.$refs.baseTable?.handleSearch();
						}
					});
			});
		},
		// 提交
		submitForm(form) {
			const params = {
				...this.formModel,
				orgFunctions: this.dialogList.map(({ functionsTwo, cohere, enforce, monitor, plan }) => ({
					cohere,
					enforce,
					monitor,
					plan,
					functionLevel: 2,
					functionDesc: functionsTwo
				}))
			};

			if (params.functionsOne) {
				params.functionLevel = 1;
				params.functionDesc = params.functionsOne;
			}

			form.validate((valid) => {
				if (valid) {
					this.formLoading = true;
					this.$axios
						.post(this.getUrlByApproval('edit'), { request: params })
						.then((res) => {
							this.formLoading = false;
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success('保存成功');
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							this.formLoading = false;
							this.dialogVisible = false;
						});
				}
			});
		},
		// eslint-disable-next-line no-unused-vars
		spanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0) {
				if (rowIndex === 0 && columnIndex === 0) {
					return [this.viewData.length, 1];
				}
				return [0, 0];
			}
		},
		async rowClick(e) {
			this.formType = 'view';
			this.dialogTitle = '详情';
			// this.viewData = [{}];
			this.dialogVisible = true;
			this.formLoading = true;
			const res = await this.getViewDetail(e);

			this.formLoading = false;
			this.viewData = res.orgFunctions?.map((val) => {
				const [plan, enforce, cohere, monitor] = [
					val.orgFunctions[0]?.functionDesc,
					val.orgFunctions[1]?.functionDesc,
					val.orgFunctions[2]?.functionDesc,
					val.orgFunctions[3]?.functionDesc
				];

				return { ...val, cohere, enforce, monitor, plan, functionDesc1: res.functionDesc };
			});
		},
		// 查询详情
		getViewDetail(e) {
			return this.$axios
				.post(this.getUrlByApproval('detail'), { request: { orgId: e.orgId } })
				.then((res) => {
					if (res.code) {
						return;
					}

					return res;
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	// padding: 20px;
	.addFunctions {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 30px 0;
		justify-content: center;
	}
}
</style>
