import { post } from "./api";
/**
 * 审核列表
 * @param params
 * @returns {*}
 */
export const getApprovalList = (params) => post('/v1/approval/list/get', params);
/**
 * 审核详情
 * @param params
 * @returns {*}
 */
export const getApprovalDetail = (params) => post('/v1/approval/approval/detail', params);
/**
 * 审核管理列表
 * @param params
 * @returns {*}
 */
export const getApprovalAll = (params) => post('/v1/approval/all', params);
/**
 * 审核管理基本信息详情
 * @param params
 * @returns {*}
 */
export const getConfig = (params) => post('/v1/approval/config/detail', params);
/**
 * 审核管理基本信息详情
 * @param params
 * @returns {*}
 */
export const saveConfig = (params) => post('/v1/approval/save/config', params);
/**
* 管理员获取审批流程属性
* @param params
* @returns {*}
*/
export const getProCon = (params) => post('/v1/approval/property/config/get', params);
/**
* 管理员保存审批属性接口
* @param params
* @returns {*}
*/
export const saveProperty = (params) => post('/v1/approval/property/save', params);
/**
 * 查询公司组织最大层级
 * @param params
 * @returns {*}
 */
export const getMaxLevel = (params) => post('/v1/org/max/level', params);
/**
 * 管理与获取审批详细流程
 * @param params
 * @returns {*}
 */
export const getProcessDetail = (params) => post('/v1/approval/process/detail', params);
/**
 * 审批配置流程更新
 * @param params
 * @returns {*}
 */
export const saveProcess = (params) => post('/v1/approval/process/update', params);
/**
 * 审批配置流程更新
 * @param params
 * @returns {*}
 */
export const saveSenior = (params) => post('/v1/approval/senior/save', params);
/**
 * 审批配置流程预览
 * @param params
 * @returns {*}
 */
export const getPreview = (params) => post('/v1/approval/process/preview', params);
/**
 * 审批配置开关
 * @param params
 * @returns {*}
 */
export const switchStatus = (params) => post('/v1/approval/switch/status', params);
/**
 * 审批管理-统计
 * @param params
 * @returns {*}
 */
export const getStatistics = (params) => post('/v1/approval/approval/statistics', params);

// 角色下拉列表
export function getRoleOptions (params = {}) {
    return post("/v1/approval/get/roles", { request: params });
}