<template>
	<div class="assessBoxInfo humanaffairs-info">
		<div class="app-container" :style="isApproval && 'margin: 0'">
			<div class="titlebox" v-if="!isApproval">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>

				<div class="title" v-if="staffInfo.staffBasicInfoDto?.subName">
					{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.name }}-{{
						staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.subName
					}}
				</div>
				<div class="title" v-else>
					{{ (staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.name) || ' ' }}
				</div>
			</div>
			<div class="picboxWrap">
				<div>
					<el-avatar :size="100" fit="cover" :src="staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.avatarUrl">
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
						</div>
					</el-avatar>
				</div>
				<div>
					<h2>{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.name }}</h2>
					<p>
						{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.orgName }} &nbsp;
						{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.jobName }}
					</p>

					<p>
						<span
							>手机号码：(+{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.areaCode }}){{
								staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.phoneNumber
							}}</span
						>

						<span>入职时间：{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.entryTime }}</span>
					</p>
				</div>
			</div>
			<div class="viewbox">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="个人信息" name="first">
						<el-collapse v-model="actives1">
							<el-collapse-item name="1">
								<template slot="title">
									<div class="titleIcon"></div>
									基本信息
								</template>
								<div class="baseInfo">
									<div class="editBtn" v-show="isShowEdit" v-if="!byStaff && !isView">
										<el-button @click="handleVisable(staffInfo.staffBasicInfoDto.id)" type="text">编辑</el-button>
									</div>
									<div>
										<div>
											<label class="left_label">曾用名/英文名/花名</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.subName
											}}</label>
										</div>
										<div>
											<label class="left_label">性别</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.sexName
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">婚姻状况</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.maritalName
											}}</label>
										</div>
										<div>
											<label class="left_label">民族</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.nationality
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">最高学历</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.highestEducationName
											}}</label>
										</div>
										<div>
											<label class="left_label">政治面貌</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.politicalName
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">毕业院校</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.graduatedSchool
											}}</label>
										</div>
										<div>
											<label class="left_label">专业</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.profession
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">手机号码</label>
											<label class="right_label">
												(+{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.areaCode }})
												{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.phoneNumber }}
											</label>
										</div>
										<div>
											<label class="left_label">出生日期</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.birthday
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">个人邮箱</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.mailbox
											}}</label>
										</div>
										<div>
											<label class="left_label">年龄</label>
											<label class="right_label">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.age }}</label>
										</div>
										<div>
											<label class="left_label">身份证号</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.identityNum
											}}</label>
										</div>
										<div>
											<label class="left_label">证件地址</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.certificateAddress
											}}</label>
										</div>
										<!-- <div>
											<label class="left_label">学历</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.highestEducationName
											}}</label>
										</div> -->
									</div>
									<!-- <div>
										<div>
											<label class="left_label">工作所在地</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.workAddress
											}}</label>
										</div>
									</div> -->
									<div>
										<div>
											<label class="left_label">家庭住址</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.homeAddress
											}}</label>
										</div>
										<div>
											<label class="left_label">籍贯</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.nativePlace
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">银行卡号</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.bankCardNo
											}}</label>
										</div>
										<div>
											<label class="left_label">开户行</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.bankDeposit
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">开户支行</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.bankBranch
											}}</label>
										</div>
										<div>
											<label class="left_label">紧急联系人</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.emergencyContact
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">紧急联系人电话</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.emergencyContactPhone
											}}</label>
										</div>
										<div>
											<label class="left_label"></label>
											<label class="right_label"> </label>
										</div>
										<!-- <div>
											<label class="left_label">政治面貌</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.politicalName
											}}</label>
										</div> -->
									</div>
									<!-- <div>
										<div>
											<label class="left_label">紧急联系人电话</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.emergencyContactPhone
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">户口所在地</label>
											<label class="right_label">{{
												staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.registeredResidenceAddress
											}}</label>
										</div>
										<div>
											<label class="left_label"></label>
											<label class="right_label"></label>
										</div>
									</div> -->
								</div>
							</el-collapse-item>
						</el-collapse>
						<el-collapse v-if="!byStaff">
							<el-collapse-item name="1">
								<template slot="title">
									<div class="titleIcon"></div>
									教育经历
								</template>
								<div v-for="(item, index) in staffInfo.staffEducationDtoList" :key="index">
									<div class="moreInfo">
										<label>教育经历{{ index + 1 }}</label>
										<div class="editBtn" v-show="isShowEdit && !isView" style="display: inline-block">
											<el-button @click="handleAddEdu('edit', item.id)" type="text">编辑</el-button>
											<el-button @click="handleDel('Edu', item)" style="color: #f56c6c" type="text">删除</el-button>
										</div>
									</div>
									<div class="baseInfo">
										<div>
											<div>
												<label class="left_label">毕业院校</label>
												<label class="right_label">{{ item.graduatedSchool }}</label>
											</div>
											<div>
												<label class="left_label">专业</label>
												<label class="right_label">{{ item.profession }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">学历</label>
												<label class="right_label">{{ item.educationName }}</label>
											</div>
											<div>
												<label class="left_label">教学方式</label>
												<label class="right_label">{{ item.educationMethodName }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">入学时间</label>
												<label class="right_label">{{ item.admissionTime }}</label>
											</div>
											<div>
												<label class="left_label">毕业时间</label>
												<label class="right_label">{{ item.graduationTime }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">是否第一学历</label>
												<label class="right_label">{{ item.whetherFirEdu == '1' ? '是' : '否' }}</label>
											</div>
											<div style="vertical-align: bottom"></div>
										</div>
									</div>
								</div>
								<div class="addBtn" v-show="isShowEdit && !isView">
									<el-button icon="el-icon-plus" type="text" @click="handleAddEdu('add')">添加教育经历</el-button>
								</div>
							</el-collapse-item>
						</el-collapse>
						<el-collapse v-if="!byStaff">
							<el-collapse-item name="1">
								<template slot="title">
									<div class="titleIcon"></div>
									工作经历
								</template>
								<div v-for="(item, index) in staffInfo.staffWorkExperienceDtoList" :key="index">
									<div class="moreInfo">
										<label>工作经历{{ index + 1 }}</label>
										<div class="editBtn" v-show="isShowEdit && !isView" style="display: inline-block">
											<el-button @click="handleAddWork('edit', item.id)" type="text">编辑</el-button>
											<el-button @click="handleDel('Work', item)" style="color: #f56c6c" type="text">删除</el-button>
										</div>
									</div>
									<div class="baseInfo">
										<div>
											<div>
												<label class="left_label">公司名称</label>
												<label class="right_label">{{ item.company }}</label>
											</div>
											<div>
												<label class="left_label">部门</label>
												<label class="right_label">{{ item.departmentName }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">岗位</label>
												<label class="right_label">{{ item.officeName }}</label>
											</div>
											<div>
												<label class="left_label">工作开始时间</label>
												<label class="right_label">{{ item.workStartTime }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">工作结束时间</label>
												<label class="right_label">{{ item.workEndTime }}</label>
											</div>
											<div>
												<label class="left_label">离职原因</label>
												<label class="right_label">
													<el-tooltip placement="top" effect="light" popper-class="popper_class">
														<div slot="content" class="popper_class">{{ item.quitReason }}</div>
														<div class="quit_reason">{{ item.quitReason }}</div>
													</el-tooltip>
												</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">证明人</label>
												<label class="right_label">{{ item.witness }}</label>
											</div>
											<div>
												<label class="left_label">证明人联系电话</label>
												<label class="right_label">{{ item.witnessContactPhone }}</label>
											</div>
										</div>
									</div>
								</div>
								<div class="addBtn" v-show="isShowEdit && !isView">
									<el-button icon="el-icon-plus" type="text" @click="handleAddWork('add')">添加工作经历</el-button>
								</div>
							</el-collapse-item>
						</el-collapse>
						<el-collapse v-if="!byStaff">
							<el-collapse-item name="1">
								<template slot="title">
									<div class="titleIcon"></div>
									项目经历
								</template>
								<div v-for="(item, index) in staffInfo.staffProjectExperienceDtoList" :key="index">
									<div class="moreInfo" style="margin-top: 15px">
										<label>项目经历{{ index + 1 }}</label>
										<div class="editBtn" v-show="isShowEdit && !isView" style="display: inline-block">
											<el-button @click="handleAddProject('edit', item.id)" type="text">编辑</el-button>
											<el-button @click="handleDel('Project', item)" style="color: #f56c6c" type="text">删除</el-button>
										</div>
									</div>
									<div class="baseInfo">
										<div>
											<div>
												<label class="left_label">项目名称</label>
												<label class="right_label">{{ item.proName }}</label>
											</div>
											<div></div>
										</div>
										<div>
											<div>
												<label class="left_label">项目开始时间</label>
												<label class="right_label">{{ item.proStartTime }}</label>
											</div>
											<div>
												<label class="left_label">项目结束时间</label>
												<label class="right_label">{{ item.proEndTime }}</label>
											</div>
										</div>
										<div>
											<div class="row_label">
												<label class="left_label">项目介绍</label>
												<label class="right_label">{{ item.proIntrod }}</label>
											</div>
										</div>
										<div>
											<div class="row_label">
												<label class="left_label">职责描述</label>
												<label class="right_label">{{ item.proDuties }}</label>
											</div>
										</div>
										<div>
											<div class="row_label">
												<label class="left_label">主要业绩</label>
												<label class="right_label">{{ item.proPerfor }}</label>
											</div>
										</div>
									</div>
								</div>
								<div class="addBtn" style="margin-top: 15px" v-show="isShowEdit && !isView">
									<el-button icon="el-icon-plus" type="text" @click="handleAddProject('add')">添加项目经历</el-button>
								</div>
							</el-collapse-item>
						</el-collapse>
						<el-collapse v-if="!byStaff">
							<el-collapse-item name="1">
								<template slot="title">
									<div class="titleIcon"></div>
									培训记录
								</template>
								<div v-for="(item, index) in staffInfo.staffTrainingRecordsDtoList" :key="index">
									<div class="moreInfo">
										<label>培训记录{{ index + 1 }}</label>
										<div class="editBtn" v-show="isShowEdit && !isView" style="display: inline-block">
											<el-button @click="handleAddTraining('edit', item.id)" type="text">编辑</el-button>
											<el-button @click="handleDel('Training', item)" style="color: #f56c6c" type="text">删除</el-button>
										</div>
									</div>
									<div class="baseInfo">
										<div>
											<div>
												<label class="left_label">培训课程</label>
												<label class="right_label">{{ item.traCourse }}</label>
											</div>
											<div>
												<label class="left_label">培训开始时间</label>
												<label class="right_label">{{ item.traStartTime }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">工作结束时间</label>
												<label class="right_label">{{ item.traEndTime }}</label>
											</div>
											<div>
												<label class="left_label">培训时长</label>
												<label class="right_label">{{ item.traTime }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">培训机构名称</label>
												<label class="right_label">{{ item.traOrgName }}</label>
											</div>
											<div>
												<label class="left_label">考核结果</label>
												<label class="right_label">{{ item.exaResults }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">培训地点</label>
												<label class="right_label">{{ item.traAddress }}</label>
											</div>
											<div>
												<label class="left_label">培训方式</label>
												<label class="right_label">
													<el-tooltip placement="top" effect="light" popper-class="popper_class">
														<div slot="content" class="popper_class">{{ item.traMode }}</div>
														<div class="quit_reason">{{ item.traMode }}</div>
													</el-tooltip>
												</label>
											</div>
										</div>
									</div>
								</div>
								<div class="addBtn" v-show="isShowEdit && !isView">
									<el-button icon="el-icon-plus" type="text" @click="handleAddTraining('add')">添加培训经历</el-button>
								</div>
							</el-collapse-item>
						</el-collapse>
						<el-collapse v-if="!byStaff">
							<el-collapse-item name="1">
								<template slot="title">
									<div class="titleIcon"></div>
									家庭状况
								</template>
								<div v-for="(item, index) in staffInfo.staffRelativesContactInfoList" :key="index">
									<div class="moreInfo">
										<label>家庭状况{{ index + 1 }}</label>
										<div class="editBtn" v-show="isShowEdit && !isView" style="display: inline-block">
											<el-button @click="handleAddContactInfo('edit', item.id)" type="text">编辑</el-button>
											<el-button @click="handleDel('ContactInfo', item)" style="color: #f56c6c" type="text">删除</el-button>
										</div>
									</div>
									<div class="baseInfo">
										<div>
											<div>
												<label class="left_label">姓名</label>
												<label class="right_label">{{ item.name }}</label>
											</div>
											<div>
												<label class="left_label">关系</label>
												<label class="right_label">{{ item.relationshipName }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">紧急联系人与否</label>
												<label class="right_label">{{ item.urgentName }}</label>
											</div>
											<div>
												<label class="left_label">联系电话1</label>
												<label class="right_label">{{ item.telOne }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">联系电话2</label>
												<label class="right_label">{{ item.telTwo }}</label>
											</div>
											<div style="vertical-align: bottom"></div>
										</div>
									</div>
								</div>
								<div class="addBtn" v-show="isShowEdit && !isView">
									<el-button icon="el-icon-plus" type="text" @click="handleAddContactInfo('add')">添加家庭状况</el-button>
								</div>
							</el-collapse-item>
						</el-collapse>
						<el-collapse v-if="!byStaff">
							<el-collapse-item name="1">
								<template slot="title">
									<div class="titleIcon"></div>
									证照材料
								</template>
								<div v-for="(item, index) in imgInfo.responseList" :key="index" style="margin-bottom: 10px">
									<div class="moreInfoImg">
										<label>证照{{ index + 1 }}</label>
										<div class="editBtn" style="display: inline-block" v-if="!isView">
											<el-button @click="handleEdit(JSON.stringify(item))" type="text">编辑</el-button>
											<el-button @click="handleDel('IdCard', item)" style="color: #f56c6c" type="text">删除</el-button>
										</div>
									</div>
									<div class="baseInfo">
										<div>
											<div>
												<label class="left_label">证照类型</label>
												<label class="right_label">{{ item.cerTypeName }}</label>
											</div>
											<div>
												<label class="right_label">{{ item.licenseId }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">证照</label>
												<label class="right_label">
													<span
														@click="
															downloadModal({
																url: img,
																bucketName: item.cerTypeName,
																name: item.cerTypeName,
																key: idx
															})
														"
														class="avatar"
														v-for="(img, idx) in imgSrcList(item)"
														:key="idx"
													>
														<img :src="img" />
													</span>
												</label>
											</div>
										</div>
									</div>
								</div>
								<div class="addBtn" v-if="!isView">
									<el-button icon="el-icon-plus" type="text" @click="handleOpen">添加证照信息</el-button>
								</div>
								<!-- <div v-for="(item, index) in staffInfo.staffCertificateRecordList" :key="index">
									<div class="moreInfo">
										<label>证照材料{{ index + 1 }}</label>
										<div class="editBtn" v-show="isShowEdit" style="display: inline-block">
											<el-button @click="handleAddCertificates('edit', item.id)" type="text">编辑</el-button>
										</div>
									</div>
									<div class="baseInfo">
										<div>
											<div>
												<label class="left_label">证书名称</label>
												<label class="right_label">{{ item.cerName }}</label>
											</div>
											<div>
												<label class="left_label">等级</label>
												<label class="right_label">{{ item.grade }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">分数</label>
												<label class="right_label">{{ item.score || '' }}</label>
											</div>
											<div>
												<label class="left_label">颁发时间</label>
												<label class="right_label">{{ item.startTime }}</label>
											</div>
										</div>
									</div>
								</div> -->
								<!-- <div class="addBtn" v-show="isShowEdit">
									<el-button icon="el-icon-plus" type="text" @click="handleAddCertificates('add')">添加证照材料</el-button>
								</div> -->
							</el-collapse-item>
						</el-collapse>
					</el-tab-pane>
					<el-tab-pane label="工作信息" name="second">
						<el-collapse v-model="actives2">
							<el-collapse-item name="1">
								<template slot="title">
									<div class="titleIcon"></div>
									基本信息
								</template>
								<div class="baseInfo">
									<div class="editBtn" v-show="isShowEdit" v-if="!byStaff && !isView">
										<el-button @click="handleJobBaseVisable(staffJobInfo.staffBasicJobDetailDto.id)" type="text">编辑</el-button>
									</div>
									<div>
										<div>
											<label class="left_label">入职时间</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.entryTime
											}}</label>
										</div>
										<div>
											<label class="left_label">司龄</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.workingYears
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">转正时间</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.turnPositiveTime
											}}</label>
										</div>
										<div>
											<label class="left_label">工号</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.jobNumber
											}}</label>
										</div>
										<!-- <div>
                                            <label class="left_label">转正状态</label>
                                            <label class="right_label">{{
                                                staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.statusName
                                            }}</label>
                                        </div> -->
									</div>
									<div>
										<div>
											<label class="left_label">首次参加工作时间</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.firstWorkTime
											}}</label>
										</div>
										<div>
											<label class="left_label">工作邮箱</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.businessMailbox
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">员工类型</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto &&
												staffJobInfo.staffBasicJobDetailDto.hireForm &&
												hireFormList.find(({ dictId }) => dictId === staffJobInfo.staffBasicJobDetailDto.hireForm)?.dictName
											}}</label>
										</div>
										<div>
											<label class="left_label">分机号</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.extensionNo
											}}</label>
										</div>
									</div>
									<div v-if="!isApproval">
										<div>
											<label class="left_label">员工状态</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto &&
												staffJobInfo.staffBasicJobDetailDto.status &&
												staffStatusList.find(({ dictId }) => dictId === staffJobInfo.staffBasicJobDetailDto.status)?.dictName
											}}</label>
										</div>
										<div>
											<label class="left_label">职级</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.officeLevel
											}}</label>
										</div>
									</div>
									<div v-if="!isApproval">
										<div>
											<label class="left_label">职族</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.familyName
											}}</label>
										</div>
										<div>
											<label class="left_label">职级代码</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.officeCode
											}}</label>
										</div>
									</div>
									<div v-if="!isApproval">
										<!-- <div>
                                            <label class="left_label">聘用形式</label>
                                            <label class="right_label">{{
                                                staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.hireFormName
                                            }}</label>
                                        </div> -->
										<div>
											<label class="left_label">职类</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.className
											}}</label>
										</div>
										<div>
											<label class="left_label">组织</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.orgName
											}}</label>
										</div>
									</div>
									<div v-if="!isApproval">
										<div>
											<label class="left_label">职类子类</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.subclassName
											}}</label>
										</div>
										<!-- <div>
                                            <label class="left_label">职类</label>
                                            <label class="right_label">{{
                                                staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.className
                                            }}</label>
                                        </div> -->
										<div>
											<label class="left_label">职位</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.officeName
											}}</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">直接上级</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.repObjName
											}}</label>
										</div>
										<div v-if="!isApproval">
											<label class="left_label">岗位</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.jobName
											}}</label>
										</div>
										<div v-if="isApproval">
											<label class="left_label">直属下属数量</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.number
											}}</label>
										</div>
									</div>
									<div v-if="!isApproval">
										<!-- <div>
                                            <label class="left_label">职级</label>
                                            <label class="right_label">{{
                                                staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.officeLevel
                                            }}</label>
                                        </div> -->
										<!-- <div>
                                            <label class="left_label">职等</label>
                                            <label class="right_label">{{
                                                staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.jobEtc
                                            }}</label>
                                        </div> -->
										<!-- <div v-if="!isView">
											<label class="left_label">基础工资</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.baseWage
											}}</label>
										</div> -->
										<div>
											<label class="left_label">直属下属数量</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.number
											}}</label>
										</div>
										<div>
											<label class="left_label"></label>
											<label class="right_label"></label>
										</div>
									</div>
									<div>
										<!-- <div v-if="!isView && !isApproval">
											<label class="left_label">岗位工资</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.postWage
											}}</label>
										</div> -->
										<div>
											<label class="left_label">公积金账号</label>
											<label class="right_label">
												{{
													staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.accumulationFund
												}}</label
											>
										</div>
										<div>
											<label class="left_label">社保账号</label>
											<label class="right_label">
												{{
													staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.socialSecurityAccount
												}}</label
											>
										</div>
									</div>
									<!-- <div v-if="!isView && !isApproval">
										<div>
											<label class="left_label">绩效工资</label>
											<label class="right_label">
												{{
													staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.performanceWage
												}}</label
											>
										</div>
										<div>
											<label class="left_label">社保账号</label>
											<label class="right_label">
												{{
													staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.socialSecurityAccount
												}}</label
											>
										</div>
									</div> -->
									<div>
										<div>
											<label class="left_label">工作城市</label>
											<label class="right_label">
												{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.workProvinceName }}
												&nbsp; {{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.workCityName }}
											</label>
										</div>
										<div>
											<label class="left_label">社保公积金城市</label>
											<label class="right_label">
												{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.hpfProvinceName }}
												&nbsp; {{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.hpfCityName }}
											</label>
										</div>
									</div>
									<div>
										<div>
											<label class="left_label">工作地址</label>
											<label class="right_label">{{
												staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.workAddress
											}}</label>
										</div>
										<div>
											<label class="left_label"></label>
											<label class="right_label"> </label>
										</div>
									</div>
									<!-- <div>
										<div style="width: 100%">
											<label class="left_label">职责</label>
											<label class="right_label" style="width: 75%; text-overflow: unset; white-space: unset">
												{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.duty }}
											</label>
										</div>
									</div>
									<div>
										<div style="width: 100%">
											<label class="left_label">主要业务</label>
											<label class="right_label" style="width: 75%; text-overflow: unset; white-space: unset">
												{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.business }}
											</label>
										</div>
									</div> -->
								</div>
							</el-collapse-item>
						</el-collapse>
						<el-collapse v-model="actives2" v-if="!byStaff && !isApproval">
							<el-collapse-item name="2">
								<template slot="title">
									<div class="titleIcon"></div>
									岗位说明书
								</template>
								<div v-show="isShowEdit" v-if="!byStaff" style="margin: 0 20px" class="jobBook">
									<!-- <el-button type="text">编辑</el-button> -->
									<formItem
										ref="formItemAddBook"
										:formItems="dialogItems"
										@cancel="$emit('close')"
										:defaultData="formModel"
										type="view"
										style="margin: 20px 0"
									>
										<div v-for="(item, inx) in dialogList" :key="inx" style="margin-bottom: 10px">
											<formItem
												:ref="`formItem${inx}`"
												:formItems="dialogDutyItems"
												:defaultData="item"
												type="view"
												:showFooter="false"
											/>
										</div>
										<div style="margin-left: 42px">任职资格</div>
										<formItem
											ref="formItem_sen"
											:formItems="dialogSenItems"
											:defaultData="dialogSenModel"
											type="view"
											:showFooter="false"
											style="margin-left: 110px; margin-bottom: 10px"
										/>
									</formItem>
								</div>
							</el-collapse-item>
						</el-collapse>
						<!-- </el-collapse> -->
						<el-collapse v-if="!byStaff">
							<el-collapse-item name="1">
								<template slot="title">
									<div class="titleIcon"></div>

									合同信息
								</template>
								<div v-for="(item, index) in staffJobInfo.staffContractRecordDetailDtoList" :key="index">
									<div class="moreInfo">
										<label>合同信息{{ index + 1 }}</label>
										<div class="editBtn" style="display: inline-block" v-show="isShowEdit && !isView">
											<el-button @click="handleContract('edit', item.id)" type="text">编辑</el-button>
											<el-button @click="handleDel('Contract', item)" style="color: #f56c6c" type="text">删除</el-button>
										</div>
									</div>
									<div class="baseInfo contractInfo">
										<div>
											<div>
												<label class="left_label">公司名称</label>
												<label class="right_label">{{ item.companyName }}</label>
											</div>
											<div>
												<label class="left_label">现合同开始时间</label>
												<label class="right_label">{{ item.contractStartTime }}</label>
											</div>
											<div>
												<label class="left_label">现合同结束时间</label>
												<label class="right_label">{{ item.contractEndTime }}</label>
											</div>
										</div>
										<div>
											<div>
												<label class="left_label">合同期限</label>
												<label class="right_label">{{ findDeadLineName(item.deadline) }}</label>
											</div>
											<div>
												<label class="left_label">续签次数</label>
												<label class="right_label">{{ item.renewNumber }}</label>
											</div>
											<div style="vertical-align: bottom"></div>
										</div>
										<div style="min-height: 60px">
											<div style="width: 100%; text-align: left">
												<label class="left_label">合同文件</label>
												<label class="right_label" style="width: 80%">
													<div class="docContainer" style="width: 100%; display: flex">
														<figure
															class="docContent"
															style="width: auto"
															v-for="(file, index) in item.fileUrlList"
															:key="index"
														>
															<img src="/static/images/fj.png" style="width: 40px; height: 40px; margin-right: 5px" />
															<div style="width: auto; transform: translateY(4px)">
																<p style="font-size: 12px">{{ file.name }}({{ file.size }})</p>
																<a
																	style="color: #409eff; font-size: 12px; margin-right: 5px"
																	target="_blank"
																	:href="'http://view.officeapps.live.com/op/view.aspx?src=' + file.url"
																	>预览</a
																>
																<el-button type="text" size="small" @click="downLoadFile(file.url)">下载</el-button>
															</div>
														</figure>
													</div>
												</label>
											</div>
										</div>
									</div>
								</div>
								<div class="addBtn" v-show="isShowEdit && !isView">
									<el-button icon="el-icon-plus" type="text" @click="handleContract('add')">添加合同信息</el-button>
								</div>
							</el-collapse-item>
						</el-collapse>
					</el-tab-pane>
					<el-tab-pane label="成长记录" name="third" v-if="!byStaff && !isApproval">
						<div class="viewbox" style="background: #fff; margin: 0px 10px">
							<timeline :growthRecord="growthRecord"></timeline>
							<!-- <el-timeline style="padding: 40px 0px 0px 200px;">
                <el-timeline-item v-for="(activity, index) in growthRecord"
                                  :key="index"
                                  color="#498DF0"
                                  :timestamp="activity.time">
                  {{activity.remark}}
                </el-timeline-item>
              </el-timeline> -->
						</div>
					</el-tab-pane>
					<el-tab-pane label="人事卡" name="fourth" v-if="Number(tabIndex) === 1">
						<div class="no-print" style="text-align: right; background: #fff; margin: 0 10px; padding: 20px 30px 0 0">
							<el-button size="small" v-print="'#printPage'" type="primary" icon="el-icon-receiving">打印</el-button>
						</div>

						<div class="viewbox" style="background: #fff; margin: 0px 10px; padding: 20px 30px" id="printPage">
							<div class="baseDetail">
								<table cellspacing="0" cellpadding="0">
									<tr>
										<td class="pic" style="text-align: center" rowspan="11">
											<el-avatar
												shape="square"
												style="width: 80%; height: 100%"
												:src="staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.avatarUrl"
											></el-avatar>
											<!-- <img :src="staffInfo.staffBasicInfoDto &&staffInfo.staffBasicInfoDto.avatarUrl"> -->
											<p>{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.name }}</p>
											<p>{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.jobNumber }}</p>
										</td>
										<td style="text-align: right; background: #f6f8fa">部门</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.orgName }}
										</td>
										<td style="text-align: right; background: #f6f8fa">岗位</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.jobName }}
										</td>
										<td style="text-align: right; background: #f6f8fa">聘用类型</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.hireFormName }}
										</td>
									</tr>
									<tr>
										<td style="text-align: right; background: #f6f8fa">职级</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.officeLevel }}
										</td>
										<td style="text-align: right; background: #f6f8fa">最近合同开始</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.contractStartTime }}
										</td>
										<td style="text-align: right; background: #f6f8fa">转正日期</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.turnPositiveTime }}
										</td>
									</tr>
									<tr>
										<td style="text-align: right; background: #f6f8fa">职位</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.officeName }}
										</td>
										<td style="text-align: right; background: #f6f8fa">最近合同结束</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.contractEndTime }}
										</td>
										<td style="text-align: right; background: #f6f8fa">民族</td>
										<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.nationality }}</td>
									</tr>
									<tr>
										<td style="text-align: right; background: #f6f8fa">在职状态</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.typeName }}
										</td>
										<td style="text-align: right; background: #f6f8fa">最高学历</td>
										<td style="text-align: left">
											{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.highestEducationName }}
										</td>
										<td style="text-align: right; background: #f6f8fa">籍贯</td>
										<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.nativePlace }}</td>
									</tr>
									<tr>
										<td style="text-align: right; background: #f6f8fa">最近晋升日期</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.promoteTime }}
										</td>
										<td style="text-align: right; background: #f6f8fa">毕业院校</td>
										<td style="text-align: left">
											{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.graduatedSchool }}
										</td>
										<td style="text-align: right; background: #f6f8fa">政治面貌</td>
										<td style="text-align: left">
											{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.politicalName }}
										</td>
									</tr>
									<tr>
										<td style="text-align: right; background: #f6f8fa">招聘渠道</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.fromTypeName }}
										</td>
										<td style="text-align: right; background: #f6f8fa">专业</td>
										<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.profession }}</td>
										<td style="text-align: right; background: #f6f8fa">出生日期</td>
										<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.birthday }}</td>
									</tr>
									<tr>
										<td style="text-align: right; background: #f6f8fa">入职时间</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.entryTime }}
										</td>
										<td style="text-align: right; background: #f6f8fa">毕业年月</td>
										<td style="text-align: left">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.graduatedTime }}
										</td>
										<td style="text-align: right; background: #f6f8fa">婚姻状况</td>
										<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.maritalName }}</td>
									</tr>
									<tr>
										<td style="text-align: right; background: #f6f8fa">家庭住址</td>
										<td colspan="3" style="text-align: left">
											{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.homeAddress }}
										</td>
										<td style="text-align: right; background: #f6f8fa">家庭电话</td>
										<td style="text-align: left">
											{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.emergencyContactPhone }}
										</td>
									</tr>
									<tr>
										<td style="text-align: right; background: #f6f8fa">邮箱</td>
										<td style="text-align: left" colspan="3">
											{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.mailbox }}
										</td>
										<td style="text-align: right; background: #f6f8fa">手机</td>
										<td style="text-align: left">{{ staffInfo.staffBasicInfoDto && staffInfo.staffBasicInfoDto.phoneNumber }}</td>
									</tr>
									<tr>
										<td style="text-align: right; background: #f6f8fa">职责</td>
										<td style="line-height: 20px; text-align: justify; padding: 10px" colspan="5">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.duty }}
										</td>
									</tr>
									<tr>
										<td style="text-align: right; background: #f6f8fa">主要业务</td>
										<td style="line-height: 20px; text-align: justify; padding: 10px" colspan="5">
											{{ staffJobInfo.staffBasicJobDetailDto && staffJobInfo.staffBasicJobDetailDto.business }}
										</td>
									</tr>
								</table>
							</div>
							<div class="details">
								<div>
									<div class="detailTitle">
										<div class="titleIcon"></div>
										<div>教育经历</div>
									</div>
									<table cellspacing="0" cellpadding="0">
										<thead style="background: #f6f8fa">
											<td style="text-align: center">毕业年月</td>
											<td style="text-align: center">学历</td>
											<td style="text-align: center">毕业院校</td>
											<td style="text-align: center">专业</td>
										</thead>
										<tr v-for="(item, index) in staffInfo.staffEducationDtoList" :key="index">
											<td>{{ item.graduationTime }}</td>
											<td>{{ item.educationName }}</td>
											<td>{{ item.graduatedSchool }}</td>
											<td>{{ item.profession }}</td>
										</tr>
									</table>
								</div>
								<div>
									<div class="detailTitle">
										<div class="titleIcon"></div>
										家庭状况
									</div>
									<table cellspacing="0" cellpadding="0">
										<thead style="background: #f6f8fa">
											<td style="text-align: center">关系</td>
											<td style="text-align: center">姓名</td>
											<!-- <td>出生日期</td> -->
											<td style="text-align: center">紧急联系人与否</td>
											<td style="text-align: center">家庭电话</td>
										</thead>
										<tr v-for="(item, index) in staffInfo.staffRelativesContactInfoList" :key="index">
											<td style="height: 50px">{{ item.relationshipName }}</td>
											<td style="height: 50px">{{ item.name }}</td>
											<!-- <td>{{item.birthday}}</td> -->
											<td style="height: 50px">{{ item.urgentName }}</td>
											<td style="height: 50px">{{ item.telOne }}</td>
										</tr>
									</table>
								</div>
							</div>
							<div class="details">
								<div>
									<div class="detailTitle">
										<div class="titleIcon"></div>
										紧急联络
									</div>
									<table cellspacing="0" cellpadding="0">
										<thead style="background: #f6f8fa">
											<td style="text-align: center">关系</td>
											<td style="text-align: center">姓名</td>
											<td style="text-align: center">主要联系电话</td>
											<td style="text-align: center">联系电话2</td>
										</thead>
										<tr v-for="(item, i) in emergencyContact" :key="i">
											<td>{{ item.relationshipName }}</td>
											<td>{{ item.name }}</td>
											<td>{{ item.telOne }}</td>
											<td>{{ item.telTwo }}</td>
										</tr>
									</table>
								</div>
								<div>
									<div class="detailTitle">
										<div class="titleIcon"></div>
										培训内容
									</div>
									<table cellspacing="0" cellpadding="0">
										<thead style="background: #f6f8fa">
											<td style="text-align: center">开始时间</td>
											<td style="text-align: center">结束时间</td>
											<td style="text-align: center">培训课程</td>
											<td style="text-align: center">考核结果</td>
										</thead>
										<tr v-for="(item, index) in staffInfo.staffTrainingRecordsDtoList" :key="index">
											<td>{{ item.traStartTime }}</td>
											<td>{{ item.traEndTime }}</td>
											<td>{{ item.traCourse }}</td>
											<td>{{ item.exaResults }}</td>
										</tr>
									</table>
								</div>
							</div>
							<div class="details">
								<div>
									<div class="detailTitle">
										<div class="titleIcon"></div>
										证书
									</div>
									<table cellspacing="0" cellpadding="0">
										<thead style="background: #f6f8fa">
											<td style="text-align: center">资格证</td>
											<td style="text-align: center">等级</td>
											<td style="text-align: center">分数</td>
											<td style="text-align: center">颁发时间</td>
											<!-- <td>结束时间</td> -->
										</thead>
										<tr v-for="(item, index) in staffInfo.staffCertificateRecordList" :key="index">
											<td>{{ item.cerName }}</td>
											<td>{{ item.grade }}</td>
											<td>{{ item.score || '' }}</td>
											<td>{{ item.startTime }}</td>
											<!-- <td>{{item.endTime}}</td> -->
										</tr>
									</table>
								</div>
								<div>
									<div class="detailTitle">
										<div class="titleIcon"></div>
										奖励/惩罚
									</div>
									<table cellspacing="0" cellpadding="0">
										<thead style="background: #f6f8fa">
											<td style="text-align: center">日期</td>
											<td style="text-align: center">区分（奖励/惩罚）</td>
											<td style="text-align: center">原因</td>
										</thead>
										<tr v-for="(item, i) in staffRewardPunishRecord" :key="i">
											<td>{{ item.createTime | parseTime('{y}-{m}-{d}') }}</td>
											<td>{{ rewardPunishType[item.type] }}</td>
											<td>{{ item.reason }}</td>
										</tr>
									</table>
								</div>
							</div>
							<div class="details">
								<div style="width: 100%; margin-right: 0">
									<div class="detailTitle">
										<div class="titleIcon"></div>
										工作经历
									</div>
									<table cellspacing="0" cellpadding="0">
										<thead style="background: #f6f8fa">
											<td style="text-align: center">开始时间</td>
											<td style="text-align: center">结束时间</td>
											<td style="text-align: center">公司名称</td>
											<td style="text-align: center">部门</td>
											<td style="text-align: center">职位</td>
										</thead>
										<tr v-for="(item, index) in staffInfo.staffWorkExperienceDtoList" :key="index">
											<td>{{ item.workStartTime }}</td>
											<td>{{ item.workEndTime }}</td>
											<td>{{ item.company }}</td>
											<td>{{ item.departmentName }}</td>
											<td>{{ item.officeName }}</td>
										</tr>
									</table>
								</div>
							</div>
							<div class="details">
								<div style="width: 100%; margin-right: 0">
									<div class="detailTitle">
										<div class="titleIcon"></div>
										发命事项
									</div>
									<table cellspacing="0" cellpadding="0">
										<thead style="background: #f6f8fa">
											<td style="min-width: 200px; text-align: center">命令日期</td>
											<td style="min-width: 200px; text-align: center">人事命令</td>
											<td>描述</td>
										</thead>
										<tr v-for="(item, index) in growthRecord" :key="index">
											<td style="min-width: 200px; line-height: 30px; text-align: center">{{ item.time }}</td>
											<td style="min-width: 200px; line-height: 30px; text-align: center">{{ item.growthTypeName }}</td>
											<td v-html="item.remark" style="line-height: 20px; padding: 10px; text-align: justify"></td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<el-dialog title="修改个人信息" center :visible.sync="dialogVisible" width="70%">
			<el-form
				:model="editUserParams"
				:label-position="labelPosition"
				ref="baseParams"
				:rules="rules"
				label-width="170px"
				:inline="true"
				class="demo-form-inline formItemClass"
			>
				<el-col :span="12">
					<el-form-item label="曾用名/英文名/花名" style="font-weight: normal">
						<el-input v-model="editUserParams.subName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="性别" style="font-weight: normal">
						<el-input :disabled="true" v-model="editUserParams.sexName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="婚姻状况" :span="12" prop="maritalStatus">
						<el-select style="width: 100%" v-model="editUserParams.maritalStatus">
							<el-option
								v-for="(item, index) in maritalStatusList"
								:key="index"
								:label="item.dictName"
								:value="item.dictId"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="民族" prop="nationality">
						<el-select v-model="editUserParams.nationality" style="width: 100%" filterable placeholder="请选择民族">
							<el-option v-for="(item, i) in nationalOptions" :key="i" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="最高学历" prop="highestEducation">
						<el-select style="width: 100%" v-model="editUserParams.highestEducation">
							<el-option
								v-for="(item, index) in highestEducationList"
								:key="index"
								:label="item.dictName"
								:value="item.dictId"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="政治面貌" prop="politicalStatus">
						<el-select style="width: 100%" v-model="editUserParams.politicalStatus">
							<el-option
								v-for="(item, index) in politicalStatusList"
								:key="index"
								:label="item.dictName"
								:value="item.dictId"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="毕业院校" style="font-weight: normal">
						<el-select style="width: 100%" v-model="editUserParams.graduatedSchool" filterable allow-create default-first-option>
							<el-option v-for="(school, i) in schoolOptions" :key="i" :value="school.value"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="专业" style="font-weight: normal">
						<el-input v-model="editUserParams.profession"></el-input>
					</el-form-item>
				</el-col>
				<!-- <el-col :span="12">
					<el-form-item label="身份证号" prop="identityNum" style="font-weight: normal">
						<el-input @change="identityNumChange" v-model="editUserParams.identityNum"></el-input>
					</el-form-item>
				</el-col> -->

				<!-- <el-col :span="12">
					<el-form-item label="出生日期">
						<el-input :disabled="true" v-model.number="editUserParams.birthday"></el-input>
					</el-form-item>
				</el-col> -->
				<!-- <el-col :span="12">
					<el-form-item label="手机号码" prop="phoneNumber">
						<el-input class="input-with-select" v-model="editUserParams.phoneNumber">
							<el-select style="width: 100px" v-model="editUserParams.areaCode" slot="prepend" placeholder="请选择">
								<el-option label="+86" value="86"></el-option>
								<el-option label="+852" value="852"></el-option>
								<el-option label="+853" value="853"></el-option>
								<el-option label="+886" value="886"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
				</el-col> -->
				<el-col :span="12">
					<el-form-item prop="phoneNumber" label="手机号">
						<el-input v-model="editUserParams.areaCode" style="width: 80px"></el-input>
						<el-input style="width: calc(100% - 80px)" v-model="editUserParams.phoneNumber" maxlength="11"> </el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item prop="birthday" label="出生日期">
						<el-date-picker
							style="width: 100%"
							disabled
							v-model="editUserParams.birthday"
							type="date"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="个人邮箱" prop="mailbox">
						<el-input v-model="editUserParams.mailbox"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="年龄">
						<el-input :disabled="true" v-model.number="editUserParams.age"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="身份证号" prop="identityNum" style="font-weight: normal">
						<el-input @change="identityNumChange" v-model="editUserParams.identityNum"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item prop="certificateAddress" label="证件地址">
						<el-input v-model="editUserParams.certificateAddress"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="家庭住址" prop="homeAddress">
						<el-input v-model="editUserParams.homeAddress"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="籍贯" prop="nativePlace">
						<el-input v-model="editUserParams.nativePlace"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="银行卡号" prop="bankCardNo">
						<el-input v-model="editUserParams.bankCardNo"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="开户行" prop="bankDeposit">
						<el-input v-model="editUserParams.bankDeposit"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="开户支行" prop="bankBranch">
						<el-input v-model="editUserParams.bankBranch"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="紧急联系人" prop="emergencyContact">
						<el-input v-model="editUserParams.emergencyContact"></el-input>
					</el-form-item>
				</el-col>

				<el-col :span="12">
					<el-form-item label="紧急联系人电话" prop="emergencyContactPhone">
						<el-input v-model="editUserParams.emergencyContactPhone"></el-input>
					</el-form-item>
				</el-col>

				<!-- <el-col :span="12">
					<el-form-item label="户口所在地" prop="registeredResidenceAddress">
						<el-input v-model="editUserParams.registeredResidenceAddress"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="工作地点" prop="workAddress">
						<el-input v-model="editUserParams.workAddress"></el-input>
					</el-form-item>
				</el-col> -->
			</el-form>
			<div slot="footer" class="dialog-footer" style="clear: both">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleBaseEdit">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="eduTitle" center :visible.sync="eduVisable" width="70%">
			<el-form
				:model="eduParams"
				:label-position="labelPosition"
				ref="eduParams"
				:rules="rules"
				label-width="140px"
				:inline="true"
				class="demo-form-inline formItemClass"
			>
				<el-col :span="12">
					<el-form-item label="毕业院校" prop="graduatedSchool" style="font-weight: normal">
						<el-select style="width: 100%" v-model="eduParams.graduatedSchool" filterable allow-create default-first-option>
							<el-option v-for="(school, i) in schoolOptions" :key="i" :value="school.value"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="专业" :span="12" prop="profession">
						<el-input v-model="eduParams.profession"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="学历" prop="education">
						<el-select style="width: 100%" v-model="eduParams.education">
							<el-option
								v-for="(item, index) in highestEducationList"
								:key="index"
								:label="item.dictName"
								:value="item.dictId"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="教学方式">
						<el-select style="width: 100%" v-model="eduParams.educationMethod">
							<el-option
								v-for="(item, index) in educationMethodList"
								:key="index"
								:label="item.dictName"
								:value="item.dictId"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="入学时间">
						<el-date-picker
							:picker-options="graduationOptions"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							v-model="eduParams.admissionTime"
							type="date"
							placeholder="选择日期"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="毕业时间">
						<el-date-picker
							:picker-options="graduationOptions"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							v-model="eduParams.graduationTime"
							type="date"
							placeholder="选择日期"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="是否第一学历" prop="whetherFirEdu">
						<el-select v-model="eduParams.whetherFirEdu" style="width: 100%">
							<el-option label="是" value="1"></el-option>
							<el-option label="否" value="2"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-form>
			<div slot="footer" class="dialog-footer" style="clear: both">
				<el-button @click="eduVisable = false">取 消</el-button>
				<el-button type="primary" @click="saveStaffEducationInfo">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="workTitle" center :visible.sync="workVisable" width="70%">
			<el-form
				:model="workParams"
				:label-position="labelPosition"
				:rules="rules"
				ref="workParams"
				label-width="140px"
				class="demo-form-inline formItemClass"
			>
				<el-form-item label="公司名称" style="font-weight: normal" prop="company">
					<el-input v-model="workParams.company" style="width: 50%"></el-input>
				</el-form-item>
				<el-col :span="12">
					<el-form-item label="部门" :span="12" prop="departmentName">
						<el-input v-model="workParams.departmentName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="岗位" prop="officeName">
						<el-input v-model="workParams.officeName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="工作开始时间" prop="workStartTime">
						<el-date-picker
							:picker-options="graduationOptions"
							v-model="workParams.workStartTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							placeholder="选择日期"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="工作结束时间" prop="workEndTime">
						<el-date-picker
							:picker-options="graduationOptions"
							v-model="workParams.workEndTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							placeholder="选择日期"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="离职原因" class="work">
						<el-input
							v-model="workParams.quitReason"
							type="textarea"
							maxlength="255"
							show-word-limit
							rows="4"
							class="input_textarea"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="证明人">
						<el-input v-model="workParams.witness"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="证明人联系方式">
						<el-input v-model="workParams.witnessContactPhone"></el-input>
					</el-form-item>
				</el-col>
			</el-form>
			<div slot="footer" class="dialog-footer" style="clear: both">
				<el-button @click="workVisable = false">取 消</el-button>
				<el-button type="primary" @click="saveStaffWorkExperience">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="projectTitle" center :visible.sync="projectVisable" width="70%">
			<el-form
				:model="projectParams"
				:label-position="labelPosition"
				:rules="rules"
				ref="projectParams"
				label-width="140px"
				class="demo-form-inline formItemClass"
			>
				<el-form-item label="项目名称" prop="proName" style="font-weight: normal">
					<el-input v-model="projectParams.proName" style="width: 50%"></el-input>
				</el-form-item>

				<el-col :span="12">
					<el-form-item prop="proStartTime" label="项目开始时间">
						<el-date-picker
							:picker-options="graduationOptions"
							v-model="projectParams.proStartTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							placeholder="选择日期"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item prop="proEndTime" label="项目结束时间">
						<el-date-picker
							:picker-options="graduationOptions"
							v-model="projectParams.proEndTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							placeholder="选择日期"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="项目介绍" class="work" prop="proIntrod">
						<el-input v-model="projectParams.proIntrod" type="textarea" maxlength="255" resize="none" show-word-limit rows="5"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item prop="proDuties" label="职责描述" class="work">
						<el-input v-model="projectParams.proDuties" type="textarea" maxlength="255" resize="none" show-word-limit rows="5"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item prop="proPerfor" label="主要业绩" class="work">
						<el-input v-model="projectParams.proPerfor" type="textarea" maxlength="255" resize="none" show-word-limit rows="5"></el-input>
					</el-form-item>
				</el-col>
			</el-form>
			<div slot="footer" class="dialog-footer" style="clear: both">
				<el-button @click="projectVisable = false">取 消</el-button>
				<el-button type="primary" @click="saveStaffProjectExperience">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="trainingTitle" center :visible.sync="trainingVisable" width="70%">
			<el-form
				:model="trainingParams"
				:label-position="labelPosition"
				:rules="rules"
				ref="trainingParams"
				label-width="120px"
				:inline="true"
				class="demo-form-inline formItemClass"
			>
				<el-row>
					<el-col :span="12">
						<el-form-item label="培训课程" style="font-weight: normal" prop="traCourse">
							<el-input v-model="trainingParams.traCourse"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="培训开始时间" :span="12" prop="traStartTime">
							<!-- @change="getDateDiff" -->
							<el-date-picker
								:picker-options="graduationOptions"
								@change="getDateDiff"
								v-model="trainingParams.traStartTime"
								value-format="yyyy-MM-dd"
								format="yyyy-MM-dd"
								type="date"
								style="width: 100%"
							></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="培训结束时间" prop="traEndTime">
							<!-- @change="getDateDiff" -->
							<el-date-picker
								:picker-options="graduationOptions"
								v-model="trainingParams.traEndTime"
								type="date"
								value-format="yyyy-MM-dd"
								format="yyyy-MM-dd"
								style="width: 100%"
							></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="培训时长">
							<el-input v-model="trainingParams.traTime"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="培训机构名称" prop="traOrgName">
							<el-input v-model="trainingParams.traOrgName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="考核结果">
							<el-input v-model="trainingParams.exaResults"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="培训地点">
							<el-input v-model="trainingParams.traAddress"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="培训方式" class="input_textarea">
							<el-input maxlength="64" type="textarea" show-word-limit v-model="trainingParams.traMode" class="input_area"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer" style="clear: both">
				<el-button @click="trainingVisable = false">取 消</el-button>
				<el-button type="primary" @click="saveStafftrainingRecordsInfo">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="contactinfoTitle" center :visible.sync="contactinfoVisable" width="70%">
			<el-form
				:model="familyParams"
				:label-position="labelPosition"
				:rules="rules"
				ref="familyParams"
				label-width="140px"
				:inline="true"
				class="demo-form-inline formItemClass"
			>
				<el-col :span="12">
					<el-form-item label="姓名" style="font-weight: normal" prop="name">
						<el-input v-model="familyParams.name"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="关系" :span="12" prop="relationship">
						<el-select style="width: 100%" v-model="familyParams.relationship">
							<el-option v-for="(item, index) in relationshipList" :key="index" :label="item.dictName" :value="item.dictId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="紧急联系人与否" prop="isUrgent">
						<el-select v-model="familyParams.isUrgent" style="width: 100%">
							<el-option label="是" value="1"></el-option>
							<el-option label="否" value="2"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="联系人电话1" prop="telOne">
						<el-input v-model="familyParams.telOne"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="联系人电话2">
						<el-input v-model="familyParams.telTwo"></el-input>
					</el-form-item>
				</el-col>
			</el-form>
			<div slot="footer" class="dialog-footer" style="clear: both">
				<el-button @click="contactinfoVisable = false">取 消</el-button>
				<el-button type="primary" @click="saveStaffRelatives">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="certificatesTitle" center :visible.sync="certificatesVisible" width="70%">
			<el-form
				:model="certificatesParams"
				:label-position="labelPosition"
				:rules="rules"
				ref="certificatesParams"
				label-width="120px"
				:inline="true"
				class="demo-form-inline formItemClass"
			>
				<el-col :span="12">
					<el-form-item label="证书名称" style="font-weight: normal" prop="cerName">
						<el-input v-model="certificatesParams.cerName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="等级">
						<el-input v-model="certificatesParams.grade"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="分数">
						<el-input type="number" oninput="value=value.replace(/[^\d.]/g,'')" v-model="certificatesParams.score"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="颁发时间" :span="12">
						<el-date-picker
							:picker-options="graduationOptions"
							v-model="certificatesParams.startTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<!-- <el-col :span="12">
      <el-form-item label="结束时间" >
            <el-date-picker v-model="certificatesParams.endTime" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" style="width: 100%;"></el-date-picker>
          </el-form-item>
    </el-col> -->
			</el-form>
			<div slot="footer" class="dialog-footer" style="clear: both">
				<el-button @click="certificatesVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveStaffCertificate">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="contractTitle" center :visible.sync="contractInfoVisible" width="70%">
			<el-form
				:model="contractParams"
				:rules="rules"
				ref="contractParams"
				:label-position="labelPosition"
				label-width="120px"
				:inline="true"
				class="demo-form-inline formItemClass"
			>
				<el-col :span="12">
					<el-form-item label="公司名称" style="font-weight: normal" prop="companyName">
						<el-input v-model="contractParams.companyName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="合同期限" prop="deadline">
						<el-select style="width: 100%" v-model="contractParams.deadline">
							<el-option v-for="(item, index) in deadlineList" :key="index" :label="item.dictName" :value="item.dictId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="合同开始时间" prop="contractStartTime">
						<el-date-picker
							:picker-options="graduationOptions"
							v-model="contractParams.contractStartTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="合同结束时间" :span="12" prop="contractEndTime">
						<el-date-picker
							v-model="contractParams.contractEndTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="续签次数" prop="renewNumber">
						<el-input v-model.number="contractParams.renewNumber"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="附件">
						<el-upload
							class="upload-demo"
							action="#"
							v-loading="contrachLoading"
							element-loading-text="正在上传......"
							:on-change="onUploadChange"
							:show-file-list="false"
							multiple
							:limit="3"
							:auto-upload="false"
							:on-exceed="onContrachExceed"
							accept=".pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.PDF,.DOC,.DOCX,.XLS,.XLSX,.ZIP,.RAR"
							:file-list="fileList"
						>
							<el-button size="small" type="primary">点击上传</el-button>
						</el-upload>
						<div class="docContainer" style="margin-top: 10px">
							<div class="docContent" v-for="(item, idx) in contractParams.fileUrlList" :key="idx">
								<img src="/static/images/fj.png" style="width: 40px; height: 40px; margin-right: 5px" />
								<div style="height: 50px">
									<span style="font-size: 12px; transform: translateY(-8px); display: inline-block"
										>{{ item.name }}({{ item.size }})</span
									>
									<br />
									<div style="display: inline-block; transform: translateY(-22px)">
										<a
											style="color: #409eff; font-size: 12px; margin-right: 5px"
											target="_blank"
											:href="'http://view.officeapps.live.com/op/view.aspx?src=' + item.url"
											>预览</a
										>
										<el-button type="text" size="small" @click="removeFile(idx)">删除</el-button>
									</div>
								</div>
							</div>
						</div>
						<div class="el-upload__tip">文件格式为DOC/DOCX/XLS/XLSX/PDF/ZIP/RAR，大小在5MB内，数量在3个之内</div>
					</el-form-item>
				</el-col>
			</el-form>
			<div slot="footer" class="dialog-footer" style="clear: both">
				<el-button @click="contractInfoVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveStaffContractInfo">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="修改工作信息" center :visible.sync="JobBaseVisable" width="70%">
			<el-form
				:model="jobParams"
				ref="jobParams"
				:rules="rules"
				:label-position="labelPosition"
				label-width="135px"
				:inline="true"
				class="demo-form-inline formItemClass"
			>
				<el-col :span="12">
					<el-form-item label="入职时间" style="font-weight: normal" prop="entryTime">
						<el-date-picker
							v-model="jobParams.entryTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							style="width: 100%"
							@change="getPlanTime"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item prop="workingYears" label="司龄">
						<el-input v-model="jobParams.workingYears" readonly></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="转正时间" prop="turnPositiveTime">
						<el-date-picker
							:picker-options="pickerOptions"
							v-model="jobParams.turnPositiveTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							disabled
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="工号" :span="12" prop="jobNumber">
						<el-input v-model="jobParams.jobNumber"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="首次参加工作时间" prop="firstWorkTime">
						<el-date-picker
							:picker-options="graduationOptions"
							v-model="jobParams.firstWorkTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="工作邮箱" :span="12" prop="businessMailbox">
						<el-input v-model="jobParams.businessMailbox"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item prop="hireForm" label="员工类型">
						<el-select v-model="jobParams.hireForm" placeholder="选择员工类型" style="width: 100%">
							<el-option v-for="(item, i) in hireFormList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item prop="extensionNo" label="分机号">
						<el-input v-model="jobParams.extensionNo"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="!isApproval">
					<el-form-item prop="status" label="员工状态">
						<el-select v-model="jobParams.status" placeholder="选择员工状态" style="width: 100%">
							<el-option v-for="(item, i) in staffStatusList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="!isApproval">
					<el-form-item label="职级">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.officeLevel }}</div>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="!isApproval">
					<el-form-item prop="familyName" label="职族">
						<el-input v-model="jobParams.familyName" size="small" placeholder="请输入职族名称"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="!isApproval">
					<el-form-item label="职级代码">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.officeCode }}</div>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="!isApproval">
					<el-form-item label="职类">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.className }}</div>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="!isApproval">
					<el-form-item label="组织" prop="orgId">
						<el-input v-if="depsId != null" :disabled="true" v-model="jobParams.orgName"></el-input>
						<treeselect
							v-if="depsId == null"
							:props="depProps"
							:options="deps"
							:value="depsId"
							:clearable="isClearable"
							:accordion="isAccordion"
							:level="depsId"
							@getValue="getValue2($event)"
							style="width: 100%"
						/>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="!isApproval">
					<el-form-item label="职类子类" :span="12" prop="subclassName">
						<el-input v-model="jobParams.subclassName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="!isApproval">
					<el-form-item label="职位">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.officeName }}</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="直接上级" prop="repObjId">
						<el-select
							style="width: 100%"
							placeholder="输入关键字"
							v-model="jobParams.repObjId"
							filterable
							remote
							reserve-keyword
							:remote-method="getHigherList"
							:loading="optLoading"
						>
							<el-option
								v-for="(item, index) in principalList"
								:key="index"
								:label="item.staffName"
								:value="item.staffId"
								:disabled="item.disabled"
							>
								<span>{{ item.staffName }}&nbsp;({{ item.jobNumber }})</span>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="!isApproval">
					<el-form-item label="岗位">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.jobName }}</div>
					</el-form-item>
				</el-col>
				<!-- <el-col :span="12">
					<el-form-item label="基础工资" prop="baseWage">
						<el-input v-model="jobParams.baseWage" :disabled="!baseWagechange"></el-input>
					</el-form-item>
				</el-col> -->
				<el-col :span="12">
					<el-form-item label="直属下属数量" prop="number">
						<el-input v-model.number="jobParams.number" readonly></el-input>
					</el-form-item>
				</el-col>
				<!-- <el-col :span="12">
					<el-form-item label="岗位工资" prop="postWage">
						<el-input v-model="jobParams.postWage" :disabled="!postWagechange"></el-input>
					</el-form-item>
				</el-col> -->
				<el-col :span="12">
					<el-form-item prop="accumulationFund" label="公积金账号">
						<el-input v-model="jobParams.accumulationFund"></el-input>
					</el-form-item>
				</el-col>
				<!-- <el-col :span="12">
					<el-form-item label="绩效工资" prop="performanceWage">
						<el-input v-model="jobParams.performanceWage" :disabled="!performanceWagechange"></el-input>
					</el-form-item>
				</el-col> -->
				<el-col :span="12">
					<el-form-item prop="socialSecurityAccount" label="社保账号">
						<el-input v-model="jobParams.socialSecurityAccount"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="工作城市" prop="workCity">
						<el-cascader
							:options="cityOptions"
							v-model="jobParams.workCity"
							change-on-select
							:props="{ checkStrictly: false }"
							separator=" "
							filterable
							style="width: 100%"
						></el-cascader>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="社保公积金城市" prop="hpfName">
						<el-cascader
							v-model="jobParams.hpfName"
							:options="cityOptions"
							change-on-select
							:props="{ checkStrictly: false }"
							separator=" "
							filterable
							style="width: 100%"
						></el-cascader>
					</el-form-item>
				</el-col>
				<!-- <el-col :span="12">
					<el-form-item label="职责" prop="duty">
						<el-input
							v-model="jobParams.duty"
							type="textarea"
							maxlength="255"
							resize="none"
							show-word-limit
							style="width: 100%"
							rows="5"
						></el-input>
					</el-form-item>
				</el-col> -->
				<el-col :span="12">
					<el-form-item prop="workAddress" label="工作地址">
						<el-input v-model="jobParams.workAddress"></el-input>
					</el-form-item>
				</el-col>
				<!-- <el-col :span="12">
					<el-form-item prop="bankCardNo" label="银行卡号">
						<el-input v-model="jobParams.bankCardNo"></el-input>
					</el-form-item>
				</el-col>
	-->
				<!-- <el-col :span="12">
					<el-form-item label="转正状态" prop="status">
						<el-select style="width: 100%" v-model="jobParams.status" disabled>
							<el-option v-for="(item, index) in statusList" :key="index" :label="item.dictName" :value="item.dictId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="聘用形式" prop="hireForm">
						<el-select style="width: 100%" v-model="jobParams.hireForm">
							<el-option v-for="(item, index) in hireFormList" :key="index" :label="item.dictName" :value="item.dictId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="工号">
						<el-input v-model="jobParams.jobNumber"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职族">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.familyName }}</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职类">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.className }}</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职类子类">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.subclassName }}</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职位">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.officeName }}</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职级代码">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.officeCode }}</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="岗位">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.jobName }}</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职级">
						<div class="inputDiv" @click="chooseJob">{{ jobParams && jobParams.officeLevel }}</div>
					</el-form-item>
				</el-col> -->

				<!-- <el-col :span="12">
					<el-form-item label="成本中心" prop="centerId">
						<treeselect
							:props="costProps"
							:options="costList"
							:value="jobParams.centerId"
							:clearable="isClearable"
							class="editTreeselect"
							:accordion="isAccordion"
							:level="jobParams.centerId"
							@getValue="getCostID($event)"
							style="width: 100%"
						/>
					</el-form-item>
				</el-col> -->

				<!-- <el-col :span="24">
					<el-form-item label="主要业务" class="work input_textarea" prop="business">
						<el-input
							v-model="jobParams.business"
							type="textarea"
							maxlength="255"
							show-word-limit
							resize="none"
							style="width: 100%"
							rows="5"
						></el-input>
					</el-form-item>
				</el-col> -->
			</el-form>
			<div slot="footer" class="dialog-footer" style="clear: both">
				<el-button @click="JobBaseVisable = false">取 消</el-button>
				<el-button type="primary" @click="handleCommitJobInfo">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="职级体系" :visible.sync="dialogTableVisible" top="0" width="70%">
			<el-form :inline="true" class="demo-form-inline">
				<el-form-item class="formLabel" label="职族" style="margin-right: 40px">
					<el-input v-model="searchFamilyName" placeholder="请输入职族名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="职类" style="margin-right: 40px">
					<el-input v-model="searchClassName" placeholder="请输入职类名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="职位" style="margin-right: 40px">
					<el-input v-model="searchOfficeName" placeholder="请输入职位名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="岗位" style="margin-right: 40px">
					<el-input v-model="searchJobName" placeholder="请输入岗位名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" style="margin-right: 40px">
					<el-button @click="getFamilyList" type="primary">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table :data="gridData" height="400" @current-change="handleCurrentChange" highlight-current-row>
				<el-table-column property="familyName" label="职族"></el-table-column>
				<el-table-column property="className" label="职类"></el-table-column>
				<el-table-column property="subclassName" label="职类子类"></el-table-column>
				<el-table-column property="officeName" label="职位"></el-table-column>
				<el-table-column property="officeCode" label="职级代码"></el-table-column>
				<el-table-column property="jobName" label="岗位"></el-table-column>
				<el-table-column property="officeLevel" label="职级"></el-table-column>
			</el-table>
			<div style="text-align: right; margin-top: 10px">
				<el-pagination
					background
					@current-change="handleSysPageChange"
					:current-page="systemPageNo"
					:page-size="systemPageSize"
					layout="total, prev, pager, next"
					:total="systemTotal"
				>
				</el-pagination>
			</div>

			<div slot="footer" class="dialog-footer" style="clear: both; text-align: center">
				<el-button @click="cancelSys">取 消</el-button>
				<el-button type="primary" @click="getSystem">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="downLoadVisible" title="下载证照" center @close="downLoadVisible = false">
			<div id="imgWrap">
				<img id="imgFile" width="100%" :src="downLoadUrl" alt />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="downLoad" type="primary">下载</el-button>
				<el-button type="primary" v-print="'#imgFile'">打印</el-button>
			</div>
		</el-dialog>
		<entryCertificateModal
			@handleSearch="handleSearch"
			:show.sync="showDialog"
			:licenseParams="licenseParams"
			:dialogType="dialogType"
		></entryCertificateModal>
	</div>
</template>

<script>
import axios from 'axios';
import * as api from '@/api/workbench';
import timeline from '@/components/humanafairs/timeLine';
import treeselect from '@/components/treeSelect/treeSelect';
import * as util from '@/utils/util';
import formItem from '@/views/components/components-form-item/index.vue';
import entryCertificateModal from './information/personnel/entryCertificateModal.vue';

export default {
	components: {
		timeline,
		treeselect,
		formItem,
		entryCertificateModal
	},
	props: { isApproval: { type: Boolean } },
	computed: {
		// emergencyContact () {
		//   let list = this.staffInfo.staffRelativesContactInfoList || [];
		//   return list.map(v => Number(v.isUrgent) === 1);
		// },
		pageName() {
			return this.$route?.name;
		},
		dictData() {
			return this.$store.state.app.dict;
		},
		rewardPunishType() {
			const arr = this.$store.state.app.dict.filter((item) => item.groupId === 'rewardPunishType');

			const map = {};

			arr.forEach((v) => {
				map[v.dictId] = v.dictName;
			});
			return map;
		},
		staffStatusList() {
			return this.$store.state.app.dict.filter((item) => {
				if (item.groupId == 'status' && item.dictId != '3') {
					return item.groupId == 'status';
				}
			});
		},
		byStaff() {
			return this.$route.query.index === '11';
		},
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		},
		imgSrcList() {
			return (item) =>
				[
					item.frontOfIdCard,
					item.reverseOfIdCard,
					item.eduHisCertificate,
					item.degreeCertificate,
					item.idPhoto,
					item.lifePhoto,
					item.licenseUrl
				]
					.filter((v) => v)
					.join('|')
					.split('|');
		},
		isView() {
			return this.$route.query.index === '3';
		},
		dialogItems() {
			return [
				// { label: '岗位名称', key: 'jobName', type: 'input', span: 8, disabled: true, placeholder: ' ' },
				// { label: '所属职位', key: 'officeName', type: 'input', span: 8, disabled: true, placeholder: ' ' },
				// { label: '职级代码', key: 'officeCode', type: 'input', span: 8, disabled: true, placeholder: ' ' },
				// { label: '职级', key: 'officeLevel', type: 'input', span: 8, disabled: true, placeholder: ' ' },
				// { label: '所属组织', key: 'orgName', type: 'input', span: 8, disabled: true, placeholder: ' ' },
				!this.isView && {
					label: '岗责承接',
					key: 'functionId',
					type: 'cascader',
					async: () => this.$axios.post('/v1/profession/findOrgFunctionByOrgId', { request: { orgId: '' } }),
					props: {
						disabled: 'disable',
						checkStrictly: true,
						expandTrigger: 'hover',
						children: 'orgFunctions',
						value: 'id',
						label: 'functionDesc'
						// emitPath: false
					},
					span: 24,
					placeholder: ' '
				},
				{ label: '岗位职责', key: 'gwzz', type: 'empty', style: 'height:40px', placeholder: ' ' },
				{ label: '关键职责概述', labelWidth: '206px', key: 'duty', type: 'textarea', placeholder: ' ' }
			].filter((v) => v);
		},
		getUrlByApi() {
			if (this.$route.query.subType === '20') {
				return (apiStr) => api[`${apiStr}Temp`];
			}
			return (apiStr) => api[apiStr];
		}
	},
	data() {
		return {
			contrachLoading: false,
			searchFamilyName: '',
			searchClassName: '',
			searchOfficeName: '',
			searchJobName: '',
			systemPageNo: 1,
			systemPageSize: 20,
			systemTotal: 0,
			emergencyContact: [],
			typeProps: {
				disabled: 'disable'
			},
			isShowEdit: false,
			activeName: 'first',
			isClearable: true, // 可清空（可选）
			isAccordion: true, // 可收起（可选）
			valueId: '', // 初始ID（可选）
			props: {
				// 配置项（必选）
				value: 'value',
				label: 'label',
				children: 'children'
			},
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			costProps: {
				value: 'id',
				label: 'costCenterName',
				children: 'children'
			},
			staffInfo: {
				default() {
					return {
						items: ''
					};
				}
			},
			staffJobInfo: {},
			growthRecord: [],
			staffRewardPunishRecord: [],
			tabIndex: '',
			labelPosition: 'right',
			dialogVisible: false,
			eduVisable: false,
			eduTitle: '',
			workVisable: false,
			workTitle: '',
			projectVisable: false,
			projectTitle: '',
			trainingVisable: false,
			trainingTitle: '',
			contactinfoVisable: false,
			contactinfoTitle: '',
			certificatesVisible: false,
			certificatesTitle: '',
			maritalStatusList: [],
			highestEducationList: [],
			politicalStatusList: [],
			educationMethodList: [],
			contractInfoVisible: false,
			contractTitle: '',
			baseParams: {
				staffId: '',
				identityNum: '',
				maritalStatus: '',
				birthday: '',
				phoneNumber: '',
				age: '',
				mailbox: '',
				highestEducation: '',
				homeAddress: '',
				nativePlace: '',
				emergencyContact: '',
				politicalStatus: '',
				emergencyContactPhone: '',
				nationality: '',
				registeredResidenceAddress: '',
				workAddress: '',
				sexName: '',
				profession: '',
				graduatedSchool: ''
			},
			eduParams: {
				id: '',
				staffId: '',
				graduatedSchool: '',
				profession: '',
				education: '',
				educationMethod: '',
				whetherFirEdu: '1',
				admissionTime: '',
				graduationTime: ''
			},
			workParams: {
				id: '',
				staffId: '',
				company: '',
				officeName: '',
				witness: '',
				witnessContactPhone: '',
				quitReason: '',
				departmentName: '',
				workStartTime: '',
				workEndTime: ''
			},
			projectParams: {
				id: '',
				staffId: '',
				proName: '',
				proIntrod: '',
				proDuties: '',
				proPerfor: '',
				proStartTime: '',
				proEndTime: ''
			},
			familyParams: {
				id: '',
				staffId: '',
				name: '',
				relationship: '',
				birthday: '',
				isUrgent: '',
				telOne: '',
				telTwo: ''
			},
			certificatesParams: {
				id: '',
				staffId: '',
				cerName: '',
				grade: '',
				score: '',
				startTime: '',
				endTime: ''
			},
			contractParams: {
				id: '',
				staffId: '',
				companyName: '',
				renewNumber: '',
				deadline: '',
				fileUrl: '',
				fileUrlList: [],
				contractStartTime: '',
				contractEndTime: ''
			},
			trainingParams: {
				id: '',
				staffId: '',
				traCourse: '',
				traStartTime: '',
				traEndTime: '',
				traTime: '',
				traOrgName: '',
				exaResults: '',
				traAddress: '',
				traMode: ''
			},
			statusList: [], // 转正状态
			hireFormList: [],
			staffId: '', // 员工id
			JobBaseVisable: false,
			jobParams: {
				staffId: '',
				entryTime: '',
				businessMailbox: '',
				turnPositiveTime: '',
				status: '',
				hireForm: '',
				jobNumber: '',
				orgId: '',
				officeLevel: '',
				rankId: '',
				// positionId: '',
				officeId: '',
				jobId: '',
				centerId: '',
				repObjId: '',
				workCity: '',
				hpfName: '',
				workProvinceCode: '',
				workCityCode: '',
				firstWorkTime: '',
				hpfProvinceCode: '',
				hpfCityCode: '',
				duty: '',
				business: '',
				officeClassId: '',
				relationType: '',
				objId: '',
				jobEtc: ''
			},
			baseWagechange: true,
			performanceWagechange: true,
			postWagechange: true,
			etcList: [
				{ lable: 'A', value: 'A' },
				{ lable: 'B', value: 'B' },
				{ lable: 'C', value: 'C' },
				{ lable: 'D', value: 'D' }
			],
			rankList: this.$store.state.setData.rankList,
			// levelList: [],
			positionList: [],
			rankId: '',
			actives1: ['1'],
			actives2: ['1'],
			deps: this.$store.state.setData.depsList,
			depsId: '',
			cityOptions: window.$cityList,
			schoolOptions: window.$schoolList,
			nationalOptions: window.$nationalList,
			officeList: [],
			jobList: [],
			// costList: this.$store.state.setData.centerList,
			costId: '',
			principalList: this.$store.state.setData.principalList,
			eduForm: {},
			workForm: {},
			projectForm: {},
			trainForm: {},
			familyForm: {},
			cerForm: {},
			contractForm: {},
			jobForm: {},
			deadlineList: [],
			fileList: [],
			uploadList: [],
			relationshipList: [],
			dialogTableVisible: false,
			gridData: [],
			tableData: [],
			currentRow: '',
			rules: {
				identityNum: [
					{ required: true, message: '请输入内容', trigger: 'blur' },
					{
						pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
						message: '证件号码格式有误！',
						trigger: 'blur'
					}
				],
				phoneNumber: [
					{ required: true, message: '请输入手机号码', trigger: 'blur' },
					{
						pattern: /^\d{3,11}$|^\d{3,11}$/,
						message: '请输入正确手机号码',
						trigger: 'blur'
					}
				],
				maritalStatus: [{ required: true, message: '请选择婚姻状态', trigger: 'change' }],
				mailbox: [{ required: true, type: 'email', message: '请输入邮箱', trigger: 'blur' }],
				highestEducation: [{ required: true, message: '请选择学历', trigger: 'change' }],
				homeAddress: [{ required: true, message: '请输入家庭住址', trigger: 'blur' }],
				nativePlace: [{ required: true, message: '请输入籍贯', trigger: 'blur' }],
				emergencyContact: [{ required: true, message: '请输入紧急联系人', trigger: 'blur' }],
				emergencyContactPhone: [{ required: true, message: '请输入紧急联系人电话', trigger: 'blur' }],
				politicalStatus: [{ required: true, message: '请输入政治面貌', trigger: 'blur' }],
				registeredResidenceAddress: [{ required: true, message: '请输入户口所在', trigger: 'blur' }],
				workAddress: [{ required: true, message: '请输入工作地址', trigger: 'blur' }],
				nationality: [{ required: true, message: '请输入民族', trigger: 'blur' }],
				graduatedSchool: [{ required: true, message: '请输入毕业院校', trigger: 'blur' }],
				education: [{ required: true, message: '请选择学历', trigger: 'change' }],
				profession: [{ required: true, message: '请输入专业', trigger: 'blur' }],
				whetherFirEdu: [{ required: true, message: '请选择是否第一学历', trigger: 'change' }],
				company: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
				officeName: [{ required: true, message: '请输入职位', trigger: 'blur' }],
				departmentName: [{ required: true, message: '请输入部门', trigger: 'blur' }],
				workStartTime: [{ type: 'string', required: true, message: '请选择开始时间', trigger: 'change' }],
				workEndTime: [{ type: 'string', required: true, message: '请选择结束时间', trigger: 'change' }],
				proName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
				proIntrod: [{ required: true, message: '请输入项目介绍', trigger: 'blur' }],
				proStartTime: [{ type: 'string', required: true, message: '请选择开始时间', trigger: 'change' }],
				proEndTime: [{ type: 'string', required: true, message: '请选择结束时间', trigger: 'change' }],
				proDuties: [{ required: true, message: '请输入内容', trigger: 'blur' }],
				proPerfor: [{ required: true, message: '请输入内容', trigger: 'blur' }],
				traCourse: [{ required: true, message: '请输入培训课程', trigger: 'blur' }],
				traStartTime: [{ type: 'string', required: true, message: '请选择开始时间', trigger: 'change' }],
				traEndTime: [{ type: 'string', required: false, message: '请选择结束时间', trigger: 'change' }],
				traOrgName: [{ required: true, message: '请输入内容', trigger: 'blur' }],
				name: [{ required: true, message: '请输入家属名称', trigger: 'blur' }],
				relationship: [{ required: true, message: '请输入家属关系', trigger: 'blur' }],
				isUrgent: [{ required: true, message: '请选择是否紧急联系人', trigger: 'change' }],
				telOne: [
					{ required: true, message: '请输入联系电话', trigger: 'blur' },
					{
						pattern: /^\d{3,11}$|^\d{3,11}$/,
						message: '请输入正确手机号码',
						trigger: 'blur'
					}
				],
				cerName: [{ required: true, message: '请输入证书名称', trigger: 'blur' }],
				entryTime: [{ type: 'string', required: true, message: '请选择日期', trigger: 'change' }],
				businessMailbox: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
				turnPositiveTime: [{ type: 'string', required: true, message: '请选择日期', trigger: 'change' }],
				status: [{ required: true, message: '请选择转正状态', trigger: 'change' }],
				hireForm: [{ required: true, message: '请选择聘用形式', trigger: 'change' }],
				orgId: [{ required: true, message: '请选择组织部门', trigger: 'change' }],
				jobEtc: [{ required: true, message: '请选择职等', trigger: 'change' }],
				// positionId: [{ required: true, message: '请选择职务', trigger: 'change' }],
				officeId: [{ required: true, message: '请选择职位', trigger: 'change' }],
				jobId: [{ required: true, message: '请选择岗位', trigger: 'change' }],
				centerId: [{ required: true, message: '请选择中心', trigger: 'change' }],
				repObjId: [{ required: false, message: '请选择直接上级', trigger: 'change' }],
				workCity: [{ required: true, message: '请选择工作地址', trigger: 'change' }],
				firstWorkTime: [{ type: 'string', required: true, message: '请选择日期', trigger: 'change' }],
				duty: [{ required: true, message: '请输入职责', trigger: 'blur' }],
				business: [{ required: true, message: '请输入业务', trigger: 'blur' }],
				hpfName: [{ required: true, message: '请选择公积金城市', trigger: 'change' }],
				companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
				renewNumber: [{ type: 'number', required: true, message: '请输入签约次数', trigger: 'blur' }],
				deadline: [{ required: true, message: '请选择期限', trigger: 'change' }],
				contractStartTime: [{ type: 'string', required: true, message: '请选择日期', trigger: 'change' }],
				contractEndTime: [{ type: 'string', required: true, message: '请选择日期', trigger: 'change' }]
			},
			linkageList: [],
			editUserParams: [],
			// 转正时间不得大于入职时间
			pickerOptions: {
				disabledDate: (time) => time.getTime() < new Date(this.jobParams.entryTime).getTime()
			},
			// 毕业时间不能晚于当前时间
			graduationOptions: {
				disabledDate: (time) => time.getTime() > Date.now()
			},
			optLoading: false,
			dialogList: [],
			// 职责
			dialogDutyItems: [
				{ label: '', labelWidth: '110px', key: 'dutyName', type: 'input', span: 8, placeholder: '职责' },
				{ label: '', labelWidth: '30px', key: 'dutyDetail', type: 'input', span: 16, placeholder: '详情' }
			],
			dialogSenModel: {},
			// 资格
			dialogSenItems: [
				// { label: '任职资格', key: 'rzzg', type: 'empty' },
				{ label: '基本条件', key: 'jbtj', type: 'empty', style: 'height:40px', labelPosition: 'left', placeholder: ' ' },
				{ label: '教育水平', key: 'educational', type: 'input', span: 8, placeholder: ' ' },
				{ label: '工作经验', key: 'experience', type: 'input', span: 8, placeholder: ' ' },
				{ label: '语言能力', key: 'tongue', type: 'input', span: 8, placeholder: ' ' },
				{ label: '专业知识', key: 'major', type: 'input', span: 8, placeholder: ' ' },
				{ label: '个人素质', key: 'quality', type: 'input', span: 8, placeholder: ' ' },
				{ label: '其他', key: 'other', type: 'input', span: 8, placeholder: ' ' },
				{ label: '岗位胜任要求', key: 'gwyq', type: 'empty', style: 'height:40px', labelPosition: 'left', placeholder: ' ' },
				{ label: '知识', key: 'knowledge', type: 'textarea', rows: 3, autosize: false, span: 12, placeholder: ' ' },
				{ label: '技能', key: 'skill', type: 'textarea', rows: 3, autosize: false, span: 12, placeholder: ' ' }
				// { label: '', labelWidth: '30px', key: 'detail', type: 'input', span: 16, placeholder: '详情' }
			],
			formModel: {},
			imgInfo: {},
			downLoadVisible: false,
			downLoadUrl: '',
			showDialog: false,
			licenseParams: {
				id: '',
				staffId: '',
				orgId: '',
				cerType: '',
				licenseId: '',
				licenseName: '',
				licenseUrl: '',
				type: '2'
			},
			dialogType: ''
		};
	},
	created() {
		this.$nextTick(() => {
			this.maritalStatusList = this.$store.state.app.dict.filter((item) => item.groupId == 'maritalStatus');
			this.highestEducationList = this.$store.state.app.dict.filter((item) => item.groupId == 'highestEducation');
			this.politicalStatusList = this.$store.state.app.dict.filter((item) => item.groupId == 'politicalStatus');

			this.educationMethodList = this.$store.state.app.dict.filter((item) => item.groupId == 'educationMethod');

			this.statusList = this.$store.state.app.dict.filter((item) => item.groupId == 'staffStatus');

			this.hireFormList = this.$store.state.app.dict.filter((item) => item.groupId == 'hireForm');
			this.deadlineList = this.$store.state.app.dict.filter((item) => item.groupId == 'deadline');
			this.relationshipList = this.$store.state.app.dict.filter((item) => item.groupId == 'relationship');
		});
	},

	mounted() {
		this.$nextTick(() => {
			this.tabIndex = Number(this.$route.query.index);
			// if (sessionStorage.getItem('tabIndex') == 2) {
			// 	this.isShowEdit = true;
			// }
			if (this.byStaff) {
				this.getStaffInfoDetail();
				this.getStaffJobInfoDetail();
				return;
			}
			this.isShowEdit = true;
			this.queryPosition();
			this.getLinkage();
			this.getStaffInfoDetail();
			this.getStaffJobInfoDetail();
			this.getGrowthRecord();
			this.getFamilyList();
			this.getRewardsAndPunishments();
			this.getDetailImgList();
			if (sessionStorage.getItem('secondTabIndex') && sessionStorage.getItem('secondTabIndex') != '') {
				this.activeName = sessionStorage.getItem('secondTabIndex');
			}
			if (this.$route.query.jobId && !this.isApproval) {
				this.getJobDetailById(this.$route.query.jobId);
			}
		});
	},

	methods: {
		handleEdit(item) {
			this.licenseParams = JSON.parse(item);
			this.licenseParams.staffId = Number(this.$route.query.id);
			const checkKeys = ['frontOfIdCard', 'reverseOfIdCard', 'eduHisCertificate', 'degreeCertificate', 'idPhoto', 'lifePhoto', 'licenseUrl'];

			checkKeys.forEach((key) => {
				if (this.licenseParams[key]) {
					this.licenseParams[key] = this.licenseParams[key].split('|').map((url) => ({ url, name: url }));
				}
			});
			// this.show = true;
			this.dialogType = 'edit';
			this.showDialog = true;
		},
		handleDel(type, item) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteRow(type, item);
			});
		},
		deleteRow(type, item) {
			const params = {
				request: {
					id: item.id,
					staffId: this.imgInfo.id
				}
			};

			let api = '';

			switch (type) {
				case 'Edu': // 教育
					api = 'deleteStaffEducationInfo';
					break;
				case 'Work': // 工作
					api = 'deleteStaffWorkExperience';
					break;
				case 'Project': // 项目
					api = 'deleteStaffProjectExperience';
					break;
				case 'Training': // 培训
					api = 'deleteStafftrainingRecordsInfo';
					break;
				case 'ContactInfo': // 家庭
					api = 'deleteStaffRelatives';
					break;
				case 'Contract': // 合同
					api = 'deleteStaffContractInfo';
					break;
				case 'IdCard': // 证照
					api = 'deleteLicence';
					break;

				default:
					break;
			}

			this.getUrlByApi(api)(params)
				.then((result) => {
					if (result.code == 0) {
						this.dialogVisible = false;
						// this.getDetailImgList();
						this.$message.success('删除成功');
						switch (type) {
							case 'Edu': // 教育
							case 'Work': // 工作
							case 'Project': // 项目
							case 'Training': // 培训
							case 'ContactInfo': // 家庭
								this.getStaffInfoDetail();
								break;
							case 'Contract': // 合同
								this.getStaffJobInfoDetail();
								break;
							case 'IdCard': // 证照
								this.getDetailImgList();
								break;

							default:
								break;
						}
					}
				})
				.catch(() => {});
		},
		handleSearch() {
			this.getDetailImgList();
			this.showDialog = false;
		},
		handleOpen() {
			this.licenseParams.staffId = Number(this.$route.query.id);
			this.licenseParams.id = '';
			this.licenseParams.cerType = ' ';
			this.licenseParams.licenseUrl = '';
			this.licenseParams.licenseId = '';
			this.licenseParams.fileUrlList = [];
			// this.show = true;
			this.dialogType = 'add';
			this.showDialog = true;
		},
		downLoad() {
			util.downloadByCreateA(this.downLoadUrl);
		},
		// 证照材料
		getDetailImgList() {
			this.getUrlByApi('queryLicenceDetail')({ request: { id: this.$route.query.id, type: '2' } }).then((res) => {
				this.imgInfo = res;
			});
		},
		// 下载打印dialog
		downloadModal(img) {
			this.downLoadVisible = true;
			this.downLoadUrl = img.url;
			this.$nextTick(() => {
				// 加水印
				util.waterMark({ text: this.userInfo.nickName, container: document.querySelector('#imgWrap') });
			});
		},
		// 岗位及岗位说明书详情查询
		getJobDetailById(jobId) {
			return this.$axios
				.post('/v1/profession/instructionDetailByJobId', { request: { jobId } })
				.then((res) => {
					if (res.code) {
						return;
					}
					this.$delete(res, '_responseStatusCode');
					this.dialogList = res.orgJobDutys;
					this.dialogSenModel = res;
					this.formModel = res;

					return res;
				})
				.catch(() => {});
		},
		// 直接上级
		getHigherList(query) {
			this.optLoading = true;
			api.selectPrincipal({ request: { staffName: query } })
				.then((res) => {
					this.principalList = res;
					this.optLoading = false;
				})
				.catch(() => {
					this.optLoading = false;
				});
		},
		findDeadLineName(id) {
			const res = this.deadlineList.find((v) => Number(v.dictId) === Number(id));

			if (res) {
				return res.dictName;
			}
			return '';
		},
		// 获取职务
		queryPosition() {
			api.queryPosition({
				request: {}
			})
				.then((res) => {
					this.positionList = res;
					this.$store.commit('SET_POSITIONLIST', res);
				})
				.catch(() => {});
		},
		identityNumChange() {
			const getIdInfo = util.getIdInfo(this.editUserParams.identityNum);

			this.editUserParams.sexName = getIdInfo.sex;
			this.editUserParams.sex = this.editUserParams.sexName == '男' ? (this.editUserParams.sex = '1') : (this.editUserParams.sex = '2');
			this.editUserParams.age = getIdInfo.age;
			this.editUserParams.birthday = getIdInfo.birthday;
		},
		number() {
			this.certificatesParams.score = this.certificatesParams.score.replace(/[^\d]/g, '');
		},
		// 合同删除
		removeFile(index) {
			this.fileList.splice(index, 1);
			this.contractParams.fileUrlList.splice(index, 1);
		},
		downLoadFile(url) {
			window.open(url);
		},
		onContrachExceed(files, fileList) {
			if (files.length > 3 || fileList.length + files.length > 3) {
				this.$message.error('最多上传三个附件');
			}
		},
		onUploadChange(file) {
			const isLt50M = file.size / 1024 / 1024 < 5;

			if (!isLt50M) {
				this.$alert('大小不得超过5M');
				return false;
			}
			const fileSize = this.renderSize(file.size);

			const filename = file.name;

			const formData = new FormData();

			formData.append('imgFile', file.raw);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.contrachLoading = true;
			axios
				.post('/v1/common/upload/file', formData, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					this.contractParams.fileUrlList.push({ name: filename, size: fileSize, url: res.httpUrl });
					this.contrachLoading = false;
				});
		},
		// 文件大小格式化转换
		renderSize(value) {
			// eslint-disable-next-line no-eq-null
			if (value == null || value == '') {
				return '0 Bytes';
			}
			// eslint-disable-next-line no-array-constructor
			const unitArr = new Array('Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB');

			let index = 0;

			const srcsize = parseFloat(value);

			index = Math.floor(Math.log(srcsize) / Math.log(1024));
			let size = srcsize / Math.pow(1024, index);

			size = size.toFixed(2); // 保留的小数位数
			return size + unitArr[index];
		},
		// 个人信息
		getStaffInfoDetail() {
			this.getUrlByApi('getStaffInfoDetail')({
				request: {
					id: this.$route.query.id
				}
			})
				.then((result) => {
					this.staffInfo = result;
					this.staffId = this.staffInfo.staffBasicInfoDto.staffId;
				})
				.catch(() => {});
		},
		getValue() {
			let params = {};

			params = {
				id: this.$refs['cascader'].getCheckedNodes()[0].value,
				label: this.$refs['cascader'].getCheckedNodes()[0].label
			};
			this.jobParams.rankId = this.jobParams.rankId[2];
			this.valueId = params.id;
			// this.getLevel(params);
		},
		// 获取等级
		// getLevel (params) {
		//   api.queryStaffLevelSystem({
		//     request: {
		//       "value": params.id,
		//       "label": params.lable
		//     }
		//   }).then((result) => {
		//     this.levelList = result;
		//   }).catch((err) => {
		//     console.log(err);
		//   });
		// },
		// 部门选中的值
		getValue2(eve) {
			this.depsId = eve.id;
			this.jobParams.orgId = eve.id;
		},
		getCostID(eve) {
			this.costId = eve.id;
			this.jobParams.centerId = eve.id;
		},
		// 岗位信息
		getStaffJobInfoDetail() {
			this.getUrlByApi('getStaffJobInfoDetail')({
				request: {
					id: this.$route.query.id
				}
			})
				.then((result) => {
					this.staffJobInfo = result;
					const basicJobDetail = result.staffBasicJobDetailDto;

					this.valueId = basicJobDetail.rankName;
					this.rankId = basicJobDetail.rankId;
					this.depsId = basicJobDetail.orgId.toString();
					this.costId = basicJobDetail.centerId;
					// this.getLevel({ id: this.rankId, label: basicJobDetail.codeName });
					this.queryStaffJobList(basicJobDetail.jobId);
				})
				.catch(() => {});
		},
		// 职位change事件
		currentSel(selVal) {
			this.queryStaffJobList(selVal);
		},
		// 查询岗位
		queryStaffJobList(id) {
			api.queryStaffOffice({
				request: {
					value: id
				}
			})
				.then((result) => {
					this.jobList = result;
				})
				.catch(() => {});
		},
		// 成长记录
		getGrowthRecord() {
			api.getGrowthRecord({
				request: {
					id: this.$route.query.id
				}
			})
				.then((result) => {
					this.growthRecord = result;
					// this.$set(this.growthRecord,result)
				})
				.catch(() => {});
		},
		handleClick() {
			if (this.activeName == 'second') {
				// api.costCenter({ request: {} })
				// 	.then((res) => {
				// 		this.costList = res;
				// 		this.$store.commit('SET_CENTERLIST', res);
				// 	})
				// 	.catch(() => {});
			}
		},
		getRewardsAndPunishments() {
			api.getEmployeeRewardsAndPunishments({
				id: this.$route.query.id
			}).then((res) => {
				if (res._responseStatusCode === 0) {
					this.staffRewardPunishRecord = res.staffRewardPunishRecord || [];
					if (res.staffRelativesContactInfo && res.staffRelativesContactInfo.length > 0) {
						this.emergencyContact = res.staffRelativesContactInfo.filter((v) => Number(v.isUrgent) === 1);
					} else {
						this.emergencyContact = [];
					}
				}
			});
		},
		// 编辑基本信息
		handleVisable(id) {
			this.dialogVisible = true;
			this.baseParams.staffId = this.staffId;
			this.baseParams.id = id;
			this.baseParams = this.staffInfo.staffBasicInfoDto;
			this.editUserParams = { ...this.baseParams };
			// eslint-disable-next-line radix
			this.editUserParams.phoneNumber = parseInt(this.editUserParams.phoneNumber);
		},
		handleBaseEdit() {
			this.$refs['baseParams'].validate((valid) => {
				if (valid) {
					this.getUrlByApi('getStaffUpdate')({
						request: this.editUserParams
					})
						.then((result) => {
							if (result.code == 0) {
								this.dialogVisible = false;
								this.getStaffInfoDetail();
							}
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},
		// 添加、编辑教育经历
		handleAddEdu(type, id) {
			this.eduVisable = true;
			this.eduParams.id = id;

			if (type === 'add') {
				this.eduTitle = '添加教育经历';
				// 移除表单验证
				if (this.$refs['eduParams']) {
					this.$refs['eduParams'].clearValidate();
					this.$refs['eduParams'].resetFields();
				}
				// this.$refs['eduParams'].resetFields();
				this.eduParams = {
					id: '',
					graduatedSchool: '',
					profession: '',
					education: '',
					educationMethod: '',
					whetherFirEdu: '1',
					admissionTime: '',
					graduationTime: ''
				};
			} else {
				this.eduTitle = '修改教育经历';
				// 移除表单验证
				if (this.$refs['eduParams']) {
					this.$refs['eduParams'].clearValidate();
				}
				let edus = this.staffInfo.staffEducationDtoList.filter((item) => item.id == id);

				edus = JSON.stringify(edus[0]);
				this.eduParams = JSON.parse(edus);
			}
			this.eduParams.staffId = this.staffId;
		},

		saveStaffEducationInfo() {
			this.$refs['eduParams'].validate((valid) => {
				if (valid) {
					const admissionTime = new Date(this.eduParams.admissionTime).getTime();

					const graduationTime = new Date(this.eduParams.graduationTime).getTime();

					if (admissionTime > graduationTime) {
						this.$message.error('入学时间不能大于毕业时间');
						return;
					}
					this.getUrlByApi('saveStaffEducationInfo')({
						request: this.eduParams
					})
						.then((result) => {
							if (result.code == 0) {
								this.eduVisable = false;

								this.getStaffInfoDetail();
							}
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},
		// 添加、编辑工作经历
		handleAddWork(type, id) {
			this.workVisable = true;
			this.workParams.id = id;
			if (type === 'add') {
				// 移除表单验证
				if (this.$refs['workParams']) {
					this.$refs['workParams'].clearValidate();
					this.$refs['workParams'].resetFields();
				}
				this.workTitle = '添加工作经历';
				this.workParams = {
					id: '',
					company: '',
					officeName: '',
					witness: '',
					witnessContactPhone: '',
					quitReason: '',
					departmentName: '',
					workStartTime: '',
					workEndTime: ''
				};
			} else {
				this.workTitle = '修改工作经历';
				// 移除表单验证
				if (this.$refs['workParams']) {
					this.$refs['workParams'].clearValidate();
				}
				let works = this.staffInfo.staffWorkExperienceDtoList.filter((item) => item.id == id);

				works = JSON.stringify(works[0]);
				this.workParams = JSON.parse(works);
			}
			this.workParams.staffId = this.staffId;
		},
		saveStaffWorkExperience() {
			this.$refs['workParams'].validate((valid) => {
				if (valid) {
					const start = new Date(this.workParams.workStartTime).getTime();

					const end = new Date(this.workParams.workEndTime).getTime();

					if (start > end) {
						this.$message.error('开始时间不能大于结束时间');
						return;
					}
					this.getUrlByApi('saveStaffWorkExperience')({
						request: this.workParams
					})
						.then((result) => {
							if (result.code == 0) {
								this.workVisable = false;
								this.getStaffInfoDetail();
							}
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},

		// 添加、编辑项目经历

		handleAddProject(type, id) {
			this.projectVisable = true;
			this.projectParams.id = id;

			if (type === 'add') {
				this.projectTitle = '添加项目经历';
				// 移除表单验证
				if (this.$refs['projectParams']) {
					this.$refs['projectParams'].clearValidate();
					this.$refs['projectParams'].resetFields();
				}
				this.projectParams = {
					id: '',
					proName: '',
					proIntrod: '',
					proDuties: '',
					proPerfor: '',
					proStartTime: '',
					proEndTime: ''
				};
			} else {
				// 移除表单验证
				if (this.$refs['projectParams']) {
					this.$refs['projectParams'].clearValidate();
				}
				this.projectTitle = '修改项目经历';
				let project = this.staffInfo.staffProjectExperienceDtoList.filter((item) => item.id == id);

				project = JSON.stringify(project[0]);
				this.projectParams = JSON.parse(project);
			}
			this.projectParams.staffId = this.staffId;
		},

		saveStaffProjectExperience() {
			this.$refs['projectParams'].validate((valid) => {
				if (valid) {
					const start = new Date(this.projectParams.proStartTime).getTime();

					const end = new Date(this.projectParams.proEndTime).getTime();

					if (start > end) {
						this.$message.error('开始时间不能大于结束时间');
						return;
					}
					this.getUrlByApi('saveStaffProjectExperience')({
						request: this.projectParams
					})
						.then((result) => {
							if (result.code == 0) {
								this.projectVisable = false;
								this.getStaffInfoDetail();
							}
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},

		// 添加、编辑培训经历
		handleAddTraining(type, id) {
			this.trainingVisable = true;
			this.trainingParams.id = id;
			if (type === 'add') {
				// 移除表单验证
				if (this.$refs['trainingParams']) {
					this.$refs['trainingParams'].clearValidate();
					this.$refs['trainingParams'].resetFields();
				}
				this.trainingTitle = '添加培训经历';
				this.trainingParams = {
					id: '',
					traCourse: '',
					traStartTime: '',
					traEndTime: '',
					traTime: '',
					traOrgName: '',
					exaResults: '',
					traAddress: '',
					traMode: ''
				};
			} else {
				this.trainingTitle = '修改培训经历';
				// 移除表单验证
				if (this.$refs['trainingParams']) {
					this.$refs['trainingParams'].clearValidate();
				}
				let project = this.staffInfo.staffTrainingRecordsDtoList.filter((item) => item.id == id);

				project = JSON.stringify(project[0]);
				this.trainingParams = JSON.parse(project);
			}
			this.trainingParams.staffId = this.staffId;
		},
		// 根据开始时间和结束时间计算课时
		getDateDiff() {
			// if (this.trainingParams.traStartTime != '' && this.trainingParams.traEndTime != '') {
			//   let arr1 = this.trainingParams.traStartTime.split('-');
			//   let arr2 = this.trainingParams.traEndTime.split('-');
			//   let oDate1 = new Date(arr1[1] + '-' + arr1[2] + '-' + arr1[0]) //转换为9-25-2017格式
			//   let oDate2 = new Date(arr2[1] + '-' + arr2[2] + '-' + arr2[0])
			//   this.trainingParams.traTime = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24) * 8 //把相差的毫秒数转换为天数
			// }
		},

		saveStafftrainingRecordsInfo() {
			this.$refs['trainingParams'].validate((valid) => {
				if (valid) {
					const start = new Date(this.trainingParams.traStartTime).getTime();

					const end = new Date(this.trainingParams.traEndTime).getTime();

					if (start > end) {
						this.$message.error('开始时间不能大于结束时间');
						return;
					}
					this.getUrlByApi('saveStafftrainingRecordsInfo')({
						request: this.trainingParams
					})
						.then((result) => {
							if (result.code == 0) {
								this.trainingVisable = false;
								this.getStaffInfoDetail();
							}
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},

		// 添加、编辑家庭状况

		handleAddContactInfo(type, id) {
			this.contactinfoVisable = true;
			this.familyParams.id = id;

			if (type === 'add') {
				// 移除表单验证
				if (this.$refs['familyParams']) {
					this.$refs['familyParams'].clearValidate();
					this.$refs['familyParams'].resetFields();
				}
				this.contactinfoTitle = '添加家庭状况';
				// this.$refs['familyParams'].resetFields();
				this.familyParams = {
					id: '',
					name: '',
					relationship: '',
					birthday: '',
					isUrgent: '',
					telOne: '',
					telTwo: ''
				};
			} else {
				this.contactinfoTitle = '修改家庭状况';
				if (this.$refs['familyParams']) {
					this.$refs['familyParams'].clearValidate();
				}
				const familys = this.staffInfo.staffRelativesContactInfoList.filter((item) => item.id == id);

				if (familys.length > 0) {
					this.familyParams = JSON.parse(JSON.stringify(familys[0]));
				}
			}
			this.familyParams.staffId = this.staffId;
		},

		saveStaffRelatives() {
			this.$refs['familyParams'].validate((valid) => {
				if (valid) {
					this.getUrlByApi('saveStaffRelatives')({
						request: this.familyParams
					})
						.then((result) => {
							if (result.code == 0) {
								this.contactinfoVisable = false;
								this.getStaffInfoDetail();
							}
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},
		// 添加、编辑证书记录
		handleAddCertificates(type, id) {
			this.certificatesVisible = true;
			this.certificatesParams.id = id;

			if (type === 'add') {
				if (this.$refs['certificatesParams']) {
					this.$refs['certificatesParams'].clearValidate();
					this.$refs['certificatesParams'].resetFields();
				}
				this.certificatesTitle = '添加证照材料';
				this.certificatesParams = {
					id: '',
					// "staffId": "",
					cerName: '',
					grade: '',
					score: '',
					startTime: '',
					endTime: ''
				};
			} else {
				if (this.$refs['certificatesParams']) {
					this.$refs['certificatesParams'].clearValidate();
				}
				this.certificatesTitle = '修改证书记录';
				let certificates = this.staffInfo.staffCertificateRecordList.filter((item) => item.id == id);

				certificates = JSON.stringify(certificates[0]);
				this.certificatesParams = JSON.parse(certificates);
			}
			this.certificatesParams.staffId = this.staffId;
		},
		saveStaffCertificate() {
			this.$refs['certificatesParams'].validate((valid) => {
				if (valid) {
					api.saveStaffCertificate({
						request: this.certificatesParams
					})
						.then((result) => {
							if (result.code == 0) {
								this.certificatesVisible = false;
								this.getStaffInfoDetail();
							}
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},

		// 编辑合同信息
		handleContract(type, id) {
			this.contractInfoVisible = true;
			this.contractParams.id = id;
			if (type === 'add') {
				this.fileList = [];
				this.contractTitle = '添加合同信息';
				if (this.$refs['contractParams']) {
					this.$refs['contractParams'].clearValidate();
					this.$refs['contractParams'].resetFields();
				}
				this.contractParams = {
					id: '',
					companyName: '',
					renewNumber: '',
					deadline: '',
					fileUrl: '',
					fileUrlList: [],
					contractStartTime: '',
					contractEndTime: ''
				};
			} else {
				if (this.$refs['contractParams']) {
					this.$refs['contractParams'].clearValidate();
				}
				this.certificatesTitle = '修改合同信息';
				let contracts = this.staffJobInfo.staffContractRecordDetailDtoList.filter((item) => item.id == id);

				contracts = JSON.stringify(contracts[0]);
				this.contractParams = JSON.parse(contracts);
				this.fileList = this.contractParams.fileUrlList;
			}
			this.contractParams.staffId = this.staffId;
		},
		saveStaffContractInfo() {
			this.$refs['contractParams'].validate((valid) => {
				if (valid) {
					const start = new Date(this.contractParams.contractStartTime).getTime();

					const end = new Date(this.contractParams.contractEndTime).getTime();

					if (start > end) {
						this.$message.error('开始时间不能大于结束时间');
						return;
					}
					this.getUrlByApi('saveStaffContractInfo')({
						// request: this.contractParams
						request: {
							id: this.contractParams.id,
							staffId: this.contractParams.staffId,
							companyName: this.contractParams.companyName,
							renewNumber: this.contractParams.renewNumber,
							deadline: this.contractParams.deadline,
							fileUrl: this.contractParams.fileUrl,
							fileUrlList: JSON.stringify(this.contractParams.fileUrlList),
							contractStartTime: this.contractParams.contractStartTime,
							contractEndTime: this.contractParams.contractEndTime
						}
					})
						.then((result) => {
							if (result.code == 0) {
								this.contractInfoVisible = false;
								this.getStaffJobInfoDetail();
							}
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},

		// 岗位编辑
		handleJobBaseVisable(id) {
			this.getHigherList();

			this.JobBaseVisable = true;
			// this.currentSel(this.jobParams.officeId)
			this.jobParams.staffId = this.staffId;
			this.principalList = this.$store.state.setData.principalList;
			// 直接上级不能选择当前员工
			this.principalList.forEach((item, index) => {
				this.principalList[index].disabled = false;
				if (item.staffId == this.staffId) {
					this.principalList[index].disabled = true;
				}
			});
			//   for (let item in this.principalList) {
			//     if (this.principalList[item].staffId == this.staffId) {
			//       this.principalList[item].disabled = true
			//     }
			//   }
			this.jobParams.id = id;
			this.jobParams.objId = this.staffJobInfo.staffBasicJobDetailDto.officeClassId;
			this.jobParams.relationType = this.staffJobInfo.staffBasicJobDetailDto.type;
			if (this.jobParams.objId) {
				this.getOffice();
			}
			const params = JSON.stringify(this.staffJobInfo.staffBasicJobDetailDto);

			this.jobParams = JSON.parse(params);
			// eslint-disable-next-line radix
			this.jobParams.repObjId = this.jobParams.repObjId != '' ? parseInt(this.jobParams.repObjId) : '';
			if (this.jobParams.workProvinceCode && this.jobParams.workCityCode) {
				this.jobParams.workCity = [this.jobParams.workProvinceCode, this.jobParams.workCityCode];
			}
			if (this.jobParams.hpfProvinceCode && this.jobParams.hpfCityCode) {
				this.jobParams.hpfName = [this.jobParams.hpfProvinceCode, this.jobParams.hpfCityCode];
			}
			if (this.jobParams.baseWage && this.jobParams.baseWage != 0) {
				this.baseWagechange = false;
			}
			if (this.jobParams.performanceWage && this.jobParams.performanceWage != 0) {
				this.performanceWagechange = false;
			}
			if (this.jobParams.postWage && this.jobParams.postWage != 0) {
				this.postWagechange = false;
			}
		},
		getOffice() {
			// this.jobParams.objId = this.jobParams.officeClassId;
			const params = {
				objId: this.jobParams.objId,
				type: this.jobParams.relationType
			};

			api.getRelevance({
				request: params
			})
				.then((result) => {
					this.officeList = result;
				})
				.catch(() => {});
		},
		// 查询职级
		queryStaffLevelSystem() {
			api.queryStaffLevelSystem({
				request: this.contractParams
			})
				.then((result) => {
					if (result.code == 0) {
						this.contractInfoVisible = false;
						this.getStaffJobInfoDetail();
					}
				})
				.catch(() => {});
		},
		// 职族-职类-子类级联
		getLinkage() {
			api.getLinkage({
				request: {}
			})
				.then((res) => {
					this.linkageList = res;
				})
				.catch(() => {});
		},
		// 通过级联获取职位
		handlelinkageChange() {
			this.jobParams.relationType = this.$refs['casLinkage'].getCheckedNodes()[0].data.type;
			this.jobParams.objId = this.$refs['casLinkage'].getCheckedNodes()[0].value;
			this.getOffice();
		},
		jobChange(id) {
			const item = this.officeList.filter((list) => list.officeId == id);

			if (item) {
				this.jobParams.familyCode = item[0].code;
				this.jobParams.officeLevel = item[0].officeLevel;
			}
			// 获取岗位
			this.queryStaffJobList(id);
		},
		// 修改岗位
		handleCommitJobInfo() {
			this.$refs['jobParams'].validate((valid) => {
				if (valid) {
					// if (!this.jobParams.levelAndCodeId) {
					// 	this.$message.error('请选择职族等相关信息');
					// 	return;
					// }
					this.jobParams.staffId = this.staffId;
					this.jobParams.workProvinceCode = this.jobParams.workCity[0] || '';
					this.jobParams.workCityCode = this.jobParams.workCity[1] || '';
					this.jobParams.hpfProvinceCode = this.jobParams.hpfName[0] || '';
					this.jobParams.hpfCityCode = this.jobParams.hpfName[1] || '';
					this.getUrlByApi('updateJob')({
						request: this.jobParams
					})
						.then((result) => {
							if (result.code == 0) {
								this.JobBaseVisable = false;
								this.getStaffJobInfoDetail();
							}
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},
		// 职类弹框
		chooseJob() {
			if (this.$store.state.app.range.staffIds && this.$store.state.app.range.staffIds.includes(this.staffId)) {
				this.$message.warning('当前人员正参与人岗匹配计划，请完成后修改');
				return;
			}
			this.dialogTableVisible = true;
		},
		getFamilyList() {
			this.$axios
				.post('/v1/profession/system/list', {
					request: {
						familyName: this.searchFamilyName,
						className: this.searchClassName,
						officeName: this.searchOfficeName,
						jobName: this.searchJobName,
						startTime: '',
						endTime: '',
						pageNo: this.systemPageNo,
						pageSize: this.systemPageSize
					},
					sign: ''
				})
				.then((res) => {
					this.systemTotal = res.totalNum;
					this.gridData = res.list;
					this.tableData = res.list;
				});
		},
		// 筛选表格
		filterTable(key, value) {
			if (value != '') {
				this.gridData = this.tableData.filter((data) => data[key].indexOf(value) > -1);
			} else {
				this.gridData = this.tableData;
			}
		},
		handleSysPageChange(val) {
			this.systemPageNo = val;
			this.getFamilyList();
		},
		// 获取职级体系选中的数据
		handleCurrentChange(val) {
			this.currentRow = val;
		},
		cancelSys() {
			this.currentRow = [];
			this.dialogTableVisible = false;
		},
		getSystem() {
			if (!this.currentRow) {
				this.$message.error('请选择职级体系信息');
				return;
			}
			this.jobParams.familyName = this.currentRow.familyName;
			this.jobParams.className = this.currentRow.className;
			this.jobParams.subclassName = this.currentRow.subclassName;
			this.jobParams.officeName = this.currentRow.officeName;
			this.jobParams.familyCode = this.currentRow.familyCode;
			this.jobParams.officeCode = this.currentRow.officeCode;
			this.jobParams.jobName = this.currentRow.jobName;
			this.jobParams.officeLevel = this.currentRow.officeLevel;
			this.jobParams.levelAndCodeId = this.currentRow.levelAndCodeId;
			this.jobParams.jobId = this.currentRow.jobId;
			this.dialogTableVisible = false;
		},
		getPlanTime() {
			const { entryTime, probationPeriod } = this.jobParams;

			if (entryTime) {
				// eslint-disable-next-line prefer-const
				let [year, month, date] = entryTime.split('-');

				if (probationPeriod) {
					const targetMonth = Number(month) + Number(probationPeriod);

					if (targetMonth > 12) {
						year++;
						month = targetMonth % 12;
					} else {
						month = targetMonth;
					}
					this.$set(this.jobParams, 'planTurnPositiveTime', `${year}-${month < 10 ? `0${month}` : month}-${date}`);
				}
				const workingTime = new Date().getTime() - new Date(entryTime).getTime();

				if (workingTime > 0) {
					const workingYears = (Math.round(workingTime / 100 / 3600 / 24 / 365) / 10).toString();

					this.$set(this.jobParams, 'workingYears', workingYears);
				}
			} else {
				this.$set(this.jobParams, 'planTurnPositiveTime', '');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.popper_class {
	max-width: 500px;
}
.quit_reason {
	//    overflow: hidden !important;
	display: inline-block;
	//   max-width: 500px; // 最大的宽度，必写属性
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100% !important;
}
.uploadding {
	top: 50%;
	margin-top: -21px;
	width: 100%;
	text-align: center;
	position: absolute;
}
</style>
<style lang="scss">
.humanaffairs-info .el-tabs__header {
	margin-bottom: 0px;
}
.humanaffairs-info .input_textarea .el-form-item__content {
	width: 80% !important;
}
.humanaffairs-info .el-form-item__label {
	font-weight: normal;
	line-height: 20px;
}
.custLabel {
	.el-form-item__label {
		width: 124px !important;
	}
}
.el-textarea .el-input__count {
	background: none;
}

.formItemClass .el-form-item .el-form-item__content {
	width: 60%;
}
.work {
	.el-form-item__content {
		width: 80% !important;
	}
}
</style>
<style lang="scss">
.input_area .el-input__inner {
	padding: 0 50px 0 15px;
}
.editTreeselect {
	.el-input .el-input__inner {
		height: 40px;
	}
}
.inputDiv {
	width: 100%;
	height: 40px;
	padding: 0 15px;
	background-color: #fff;
	cursor: pointer;
	background-image: none;
	border-radius: 4px;
	border: 1px solid #dcdfe6;
}
.docContainer {
	display: flex;
	.docContent {
		display: flex;
		align-items: center;
		margin-left: 10px;
		flex-wrap: wrap;
		min-width: 140px;
	}
}

.formItemClass .el-form-item {
	width: 100%;
	display: inline-flex;
	align-items: center;
}

.assessBoxInfo .app-container .viewbox .level_box_now {
	font-weight: bold;
}

.picboxWrap {
	padding: 0px 0 0 100px;
	background: #fff;
	// margin: 0 20px;
	display: flex;
	align-items: center;
	height: 140px;
	:first-child {
		margin-right: 10px;
	}
	h2 {
		margin: 10px 0 0 0;
	}
	p {
		margin: 15px 0px;
		color: #999999;
	}
}

.assessBoxInfo {
	.app-container {
		color: #484848;
		padding: 0;
		background-color: #f6f7fb;
		margin: 0 20px;
		.titlebox {
			height: 54px;
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			// margin: 20px;
			margin-top: 20px;
		}

		.viewbox {
			padding: 0px;
			// margin: 0 20px;
			.el-tabs__active-bar {
				display: none;
			}
			.el-tabs__nav {
				float: none;
				text-align: center;
				background: #f6f7fb;
				padding: 10px 0px;
			}
			.el-tabs__item {
				font-size: 18px;
				font-weight: bold;
			}
			.el-tabs__content .el-tab-pane .el-collapse {
				border-top: none;
				border-bottom: none;
				margin-bottom: 10px;
			}
			.el-collapse-item__header {
				margin: 5px 0px 0px 0px;
				border-bottom: none;
				font-size: 16px;
				font-weight: bold;
				padding: 0px 15px;
				height: 75px;
			}
			.baseInfo {
				text-align: center;
				font-size: 14px;
				.editBtn {
					text-align: right;
					padding-right: 25px;
				}
				div {
					min-height: 50px;
					div {
						display: inline-block;
						vertical-align: bottom;
						width: calc(100% / 2);
					}
					.row_label {
						width: 100%;
						height: auto;
						margin-top: 10px;
						.left_label {
							//   width: 5%;
							vertical-align: top;
						}
						.right_label {
							width: 75%;
							word-break: break-all;
						}
					}
				}
				.left_label {
					margin-right: 30px;
					color: #999999;
					text-align: right;
					width: 130px;
					vertical-align: top;
				}
				.right_label {
					width: 50%;
					overflow: hidden;
					// text-overflow: ellipsis;
					// white-space: nowrap;
					text-align: left;
					.avatar {
						display: inline-block;
						-webkit-box-sizing: border-box;
						box-sizing: border-box;
						text-align: center;
						color: #fff;
						background: #c0c4cc;
						width: 40px;
						height: 40px;
						line-height: 40px;
						font-size: 14px;
						height: 50px;
						width: 50px;
						line-height: 50px;
						margin-left: 5px;
						overflow: hidden;
						border-radius: 4px;
						&:hover {
							cursor: pointer;
						}
						img {
							display: block;
							height: 100%;
							width: 100%;
							object-fit: cover;
						}
					}
				}
				label {
					font-weight: normal;
					display: inline-block;
				}
			}
			.contractInfo {
				text-align: unset;
				padding: 15px 30px;
			}
			.contractInfo div div {
				width: calc(100% / 3);
			}
			.moreInfoImg {
				border-top: 1px solid #cccccc;
				font-size: 14px;
				font-weight: bold;
				margin: 0px 15px;
				padding-top: 15px;
				position: relative;
				label {
					margin-top: 10px;
				}
				.editBtn {
					position: absolute;
					top: 8px;
					right: 0;
				}
			}
			.moreInfo {
				border-top: 1px solid #cccccc;
				font-size: 14px;
				font-weight: bold;
				margin: 0px 15px;
				padding-top: 15px;
				position: relative;
				label {
					margin-top: 10px;
				}
				.editBtn {
					position: absolute;
					top: 8px;
					right: 0;
				}
			}
		}
	}
}
.baseDetail {
	display: flex;
	margin-top: 10px;
	table {
		width: 100%;
		min-width: 500px;
		border: 1px solid #b0bacb;
		:first-child {
			td:nth-child(even) {
				text-align: right;
				background: #f6f8fa;
				padding: 10px;
			}
			td:nth-child(odd) {
				background: #fff;
				text-align: left;
				padding: 10px;
			}
		}
		tr {
			line-height: 2.3;
			.pic {
				background: #ffffff !important;
				padding: 0px !important;
				width: 100%;
				img {
					width: 100%;
					height: 100%;
				}
				p {
					text-align: center;
					margin-bottom: 0;
					margin-top: 0;
				}
			}
		}
		td {
			border: 1px solid #b0bacb;
			vertical-align: middle;
		}
		td:nth-child(odd) {
			text-align: right;
			background: #f6f8fa;
			width: 14%;
			padding-right: 10px;
		}
		td:nth-child(even) {
			text-align: left;
			padding-left: 10px;
		}
	}
}
.details {
	display: flex;
	margin-top: 10px;
	.detailTitle {
		display: flex;
		// justify-conten:center;
		height: 30px;
		font-size: 10px;
		font-size: 18px;
	}
	div {
		width: calc(100% / 2);
	}
	div:first-child {
		margin-right: 20px;
	}
	table {
		width: 100%;
		height: 150px;
		border: 1px solid #b0bacb;
		thead {
			td {
				height: 50px;
			}
		}
		td {
			border: 1px solid #b0bacb;
			word-break: break-all;
			padding-left: 10px;
			vertical-align: middle;
		}
	}
}
.titleIcon {
	display: inline-block;
	width: 8px !important;
	height: 20px;
	background: #498df0;
	border-radius: 5px;
	vertical-align: bottom;
	margin-right: 5px !important;
}
.addBtn {
	border: 1px dashed #ccc;
	text-align: center;
	height: 40px;
	margin: 0px 15px;
	padding: 0px;
	font-size: 16px;
	&:hover {
		cursor: pointer;
	}
}
.el-timeline-item__timestamp {
	position: absolute;
	top: -6px;
	left: -90px;
	color: #000;
}
.jobBook {
	.el-input.is-disabled .el-input__inner {
		border: none;
		background-color: #fff;
		border-color: #fff;
		color: #333;
		cursor: inherit;
	}
	.el-input__suffix {
		.el-input__icon {
			display: none;
			cursor: inherit;
		}
	}
}
@media print {
	#printPage {
		padding: 0 !important ;
		background-color: #fff;
		td {
			text-align: center !important;
			font-size: 8px;
			background-color: #f6f8fa;
		}
		.details .detailTitle {
			display: flex;
			// justify-conten:center;
			align-items: center;
			height: 30px;
			font-size: 10px;
		}
	}
}
</style>
