<!--  组织负责人  -->
<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			@rowClick="rowClick"
			showOrder
		/>
		<!-- 新增组织负责人 -->
		<el-dialog
			:title="dialogTitle"
			top="30vh"
			width="900px"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<!-- <baseTable v-if="formType === 'view'" :dataList="viewData" :columns="viewColumns" :showPagination="false" :spanMethod="spanMethod" /> -->
			<formItem
				ref="formItem"
				:formItems="dialogItems"
				@cancel="dialogVisible = false"
				@submit="submitForm"
				:defaultData="formModel"
				style="margin: 20px 120px"
				:type="'edit'"
			/>
		</el-dialog>
		<!-- 导入按钮的弹窗 -->
		<Upload :visible.sync="dialogImportVisible" :config="uploadConfig" />
	</div>
</template>

<script>
import baseTable from '@/views/components/components-base-table';
import Upload from '@/components/Upload/index.vue';
import formItem from '@/views/components/components-form-item/index.vue';
import { downloadByCreateA } from '@/utils/util';
// import moment from 'moment';
export default {
	name: 'organizationalCharge',
	components: {
		baseTable,
		Upload,
		formItem
	},

	props: {
		// 审批申请页面
		isApproval: {
			type: Boolean
			// required: true
		},
		pageName: {
			type: String
		}
	},
	computed: {},
	data() {
		return {
			tableCols: [
				{ label: '组织名称', key: 'orgName' },
				{ label: '责任岗位', key: 'jobName' },
				{ label: '负责人', key: 'staffName' },
				{
					label: '操作',
					key: 'cz',
					render: (row) => (
						<div>
							{(this.isApproval || this.permissionControlBtns(this.pageName, 'Edit')) && (
								<el-button type="text" onclick={(e) => this.handleEdit(row, e)}>
									编辑
								</el-button>
							)}
							{(this.isApproval || this.permissionControlBtns(this.pageName, 'Delete')) && (
								<el-button
									type="text"
									style={'color:rgb(245, 108, 108); '}
									// disabled={this.getDelDisabled(row, inx)}
									onclick={(e) => this.handleDel(row, e)}
								>
									删除
								</el-button>
							)}
						</div>
					)
				}
			],
			queryItem: [
				{
					label: '组织',
					key: 'orgId',
					type: 'tree',
					async: () => this.$axios.post('/v1/org/list', { request: {} }),
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					span: 5,
					filter: true
				}
			],

			otherBtns: [
				(this.isApproval || this.permissionControlBtns(this.pageName, 'Add')) && {
					label: '新增组织负责人',
					icon: 'el-icon-plus',
					style: { float: 'right' },
					click: () => {
						this.dialogTitle = '新增组织负责人';
						this.formModel = {};
						this.dialogVisible = true;
					}
				},
				this.permissionControlBtns(this.pageName, 'Export') && {
					label: '导出',
					icon: 'el-icon-download',
					style: { float: 'right' },
					click: (_, { orgId }) => {
						downloadByCreateA(`/v1/org/exportOrgLeader?params=${encodeURIComponent(JSON.stringify({ orgId }))}`);
					}
				},
				this.permissionControlBtns(this.pageName, 'Import') && {
					label: '导入',
					icon: 'el-icon-upload',
					style: { float: 'right' },
					click: () => {
						this.dialogImportVisible = true;
					}
				}
			].filter((v) => v),
			dialogVisible: false,
			formModel: {},
			dialogItems: [
				{
					label: '组织名称',
					key: 'orgId',
					type: 'tree',
					options: this.$store.state.setData?.options?.orgId || [],
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'orgName',
					filter: true,
					rules: { required: true, message: '请选择', trigger: 'change' }
				},
				{
					label: '责任岗位',
					key: 'jobId',
					type: 'selectSearch',
					firstRequest: true,
					async: (query) =>
						this.$axios
							.post('/v1/profession/job/list', { request: { jobName: query || '', pageNo: 1, pageSize: 40 } })
							.then((v = {}) => v.list.map(({ jobName, id }) => ({ dictId: id, dictName: jobName }))),
					change: async (formModel, _, item) => {
						const { jobId } = formModel;
						const { staff = [] } = await this.getDetailInfo({ id: jobId });

						this.$set(formModel, 'staffId', '');
						this.$set(formModel, 'staffName', '');
						item.forEach((val) => {
							if (val.key === 'staffId') {
								this.$set(val, 'options', staff?.map(({ staffName, staff_id }) => ({ dictId: staff_id, dictName: staffName })) || []);
								if (!jobId) {
									this.$delete(val, 'options');
								}
							}
						});
					}
				},
				{
					label: '负责人',
					key: 'staffId',
					showKey: 'staffName',
					type: 'selectSearch',
					firstRequest: true,
					async: (query) =>
						this.$axios
							.post('/v1/common/findStaffList', { request: { staffName: query || '', pageNo: 1, pageSize: 40 } })
							.then((v = {}) => v.map(({ staffName, staffId }) => ({ dictId: staffId, dictName: staffName }))),
					change: async (formModel) => {
						const { staffId } = formModel;
						const { job = {} } = await this.getDetailInfo({ staffId });

						if (job.id) {
							this.$set(formModel, 'jobId', job.id);
						}
						if (job.jobName) {
							this.$set(formModel, 'jobName', job.jobName);
						}
					},
					rules: { required: true, message: '请选择', trigger: 'change' }
				}
			],
			dialogTitle: '添加',
			formType: 'edit',
			dataList: [{}],
			// 导入配置
			uploadConfig: {
				dictId: 'importTargetInfo',
				groupId: 'importTemplate',
				importUrl: '/v1/org/importOrgLeader',
				successMsg: '导入成功',
				callBack: () => {
					this.$refs.baseTable?.handleSearch();
					this.dialogImportVisible = false;
				}
			},
			dialogImportVisible: false
		};
	},
	created() {},
	mounted() {},
	methods: {
		// 接口区分
		getUrlByApproval(type) {
			let url = '';
			const isApp = this.isApproval;

			switch (type) {
				case 'list':
					url = isApp ? '/v1/org/orgLeaderTempListByPage' : '/v1/org/orgLeaderListByPage';
					break;
				case 'delete':
					url = isApp ? '/v1/org/delOrgLeaderTemp' : '/v1/org/delOrgLeader';
					break;
				case 'edit':
					url = isApp ? '/v1/org/saveOrgLeaderTemp' : '/v1/org/saveOrgLeader';
					break;

				default:
					break;
			}
			return url;
		},
		getTableData(params, callback) {
			callback(this.$axios.post(this.getUrlByApproval('list'), params));
		},
		// 编辑
		handleEdit(row, e) {
			e.stopPropagation();
			this.formType = 'edit';
			this.dialogTitle = '编辑';
			this.formModel = { ...row };
			this.dialogVisible = true;
		},
		// 删除
		handleDel(row, e) {
			e.stopPropagation();
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post(this.getUrlByApproval('delete'), {
						request: { id: row.id },
						sign: ''
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.$refs.baseTable?.handleSearch();
						}
					});
			});
		},
		// 提交
		submitForm(form) {
			form.validate((valid) => {
				if (valid) {
					this.$axios
						.post(this.getUrlByApproval('edit'), { request: this.formModel })
						.then((res) => {
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success('保存成功');
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		},
		// 点击table行
		rowClick(e) {
			console.log(e, 'rowClick');
		},
		// 根据岗位查询员工或根据员工查询岗位
		getDetailInfo(data) {
			return this.$axios
				.post('/v1/org/findJobStaffInfo', { request: data })
				.then((res) => {
					if (res.code) {
						return {};
					}
					return res;
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss"></style>
